import React, { useState } from 'react';
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';
import TableCarts from '../Cart/TableCarts';
import FilterSection from '../FilterSection/FilterSection';

const SecondSection = ({ searchGet }) => {
  const [brandName, setBrandName] = useState('');
  const [selectNumber, setSelectNumber] = useState('6 Cars');
  const [allCar, setAllCar] = useState('No');
  const handleClearAll = () => {
    setBrandName('');
    setAllCar('Yes');
  };

  return (
    <div>
      <h1 class="py-5 bg-slate-900 text-white pl-3 md:pl-20 flex items-center gap-x-3">
        Home Section <MdKeyboardDoubleArrowRight /> {brandName}{' '}
        <span className="md:ml-20">Select Cars </span>{' '}
        <MdKeyboardDoubleArrowRight /> {selectNumber}
      </h1>
      <div className="ml-2 md:ml-10 mt-5 ">
        <div className="grid grid-cols-1 md:grid-cols-12 ">
          <div className="col-span-2 mr-14 md:mr-0 ml-5 md:ml-0 ">
            <FilterSection
              brandName={brandName}
              setBrandName={setBrandName}
              handleClearAll={handleClearAll}
            />
          </div>
          <div className="col-span-10 rounded-md">
            <TableCarts
              brandName={brandName}
              setSelectNumber={setSelectNumber}
              selectNumber={selectNumber}
              allCar={allCar}
              setAllCar={setAllCar}
              searchGet={searchGet}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondSection;
