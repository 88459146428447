import React from 'react';
import Footer from '../../Share/Footer';
import BannerTail from './Banner/BannerTail';
import BodyType from './BodyType/BodyType';
import Contact from './Contact/Contact';
import HappyClient from './HappyClient/HappyClient';
import PopularBrands from './PopularBrands/PopularBrands';
import SecondSection from './SecondSection/SecondSection';

const Home = ({
  searchGet,
  setSearchGet,
  setBodyType,
  setPriceFilter,
  setBrandName,
}) => {
  return (
    <div className="  bg-slate-50 pt-[16px] md:pt-[66px]">
      {/* <Banner /> */}
      <BannerTail setPriceFilter={setPriceFilter} setBrandName={setBrandName} />
      <SecondSection searchGet={searchGet} setSearchGet={setSearchGet} />
      <PopularBrands setBrandName={setBrandName} />
      <BodyType setBodyType={setBodyType} />
      <HappyClient />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
