import React, { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import banner1 from '../../../../Images/Banner/b1.jpg';
import banner2 from '../../../../Images/Banner/b2.jpg';
import banner3 from '../../../../Images/Banner/b3.jpg';
import banner4 from '../../../../Images/Banner/b4.jpg';
import banner5 from '../../../../Images/Banner/b5.jpg';
import banner6 from '../../../../Images/Banner/b6.jpg';
import banner7 from '../../../../Images/Banner/b7.jpg';

import './BannerTail.css';
import SearchCart from './SearchCart/SearchCart';

const BannerTail = ({ setPriceFilter, setBrandName }) => {
  const [autoplay, setAutoplay] = useState(true);
  const [banners, setBanner] = useState([]);

  useEffect(() => {
    fetch(`https://car-server-aus.onrender.com/banner`)
      .then(res => res.json())
      .then(data => setBanner(data));
  }, []);
  const handleMouseEnter = () => {
    setAutoplay(false);
  };

  const handleMouseLeave = () => {
    setAutoplay(true);
  };

  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        // autoplay={
        //   autoplay ? { delay: 10000, disableOnInteraction: false } : false
        // }
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <SwiperSlide>
          <div
            style={{
              backgroundImage: `url(${banner1})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              width: '100%',
            }}
            className="h-[400px] md:h-[820px] "
          >
            <SearchCart
              setPriceFilter={setPriceFilter}
              setBrandName={setBrandName}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            style={{
              backgroundImage: `url(${banner2})`,
              // backgroundImage: `url(${banner1})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              width: '100%',
            }}
            className="h-[400px] md:h-[820px]"
          >
            <SearchCart
              setPriceFilter={setPriceFilter}
              setBrandName={setBrandName}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            style={{
              backgroundImage: `url(${banner3})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              width: '100%',
            }}
            className="h-[400px] md:h-[820px]"
          >
            <SearchCart
              setPriceFilter={setPriceFilter}
              setBrandName={setBrandName}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            style={{
              backgroundImage: `url(${banner4})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              width: '100%',
            }}
            className="h-[400px] md:h-[820px]"
          >
            <SearchCart
              setPriceFilter={setPriceFilter}
              setBrandName={setBrandName}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            style={{
              backgroundImage: `url(${banner5})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              width: '100%',
            }}
            className="h-[400px] md:h-[820px]"
          >
            <SearchCart
              setPriceFilter={setPriceFilter}
              setBrandName={setBrandName}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            style={{
              backgroundImage: `url(${banner6})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              width: '100%',
            }}
            className="h-[400px] md:h-[820px]"
          >
            <SearchCart
              setPriceFilter={setPriceFilter}
              setBrandName={setBrandName}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            style={{
              backgroundImage: `url(${banner7})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              width: '100%',
            }}
            className="h-[400px] md:h-[820px]"
          >
            <SearchCart
              setPriceFilter={setPriceFilter}
              setBrandName={setBrandName}
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default BannerTail;

// import React, { useState } from 'react';
// import banner1 from '../../../../Images/Banner/baneer6.webp';
// import banner2 from '../../../../Images/Banner/bannaer5.jpeg';
// import banner3 from '../../../../Images/Banner/banner1.webp';
// import banner4 from '../../../../Images/Banner/banner2.jpg';
// import banner5 from '../../../../Images/Banner/banner3.jpg';

// import { Swiper, SwiperSlide } from 'swiper/react';

// // Import Swiper styles
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';

// import './BannerTail.css';

// // import required modules
// import { Autoplay, Navigation, Pagination } from 'swiper/modules';

// const BannerTail = () => {
//   const [swiper, setSwiper] = useState(null);

//   const handleMouseEnter = () => {
//     if (swiper) {
//       swiper.autoplay.stop();
//     }
//   };

//   const handleMouseLeave = () => {
//     if (swiper) {
//       swiper.autoplay.start();
//     }
//   };
//   return (
//     <>
//       <Swiper
//         spaceBetween={30}
//         centeredSlides={true}
//         autoplay={{
//           delay: 3000,
//           disableOnInteraction: false,
//         }}
//         pagination={{
//           clickable: true,
//         }}
//         navigation={true}
//         modules={[Autoplay, Pagination, Navigation]}
//         className="mySwiper"
//         onSwiper={setSwiper}
//         onMouseEnter={handleMouseEnter}
//         onMouseLeave={handleMouseLeave}
//       >
//         <SwiperSlide>
//           <img src={banner2} alt="" />
//         </SwiperSlide>
//         <SwiperSlide>
//           <img src={banner5} alt="" />
//         </SwiperSlide>
//         <SwiperSlide>
//           <img src={banner1} alt="" />
//         </SwiperSlide>
//         <SwiperSlide>
//           <img src={banner4} alt="" />
//         </SwiperSlide>
//         <SwiperSlide>
//           <img src={banner3} alt="" />
//         </SwiperSlide>
//       </Swiper>
//     </>
//   );
// };

// export default BannerTail;
