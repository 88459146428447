import React from 'react';
import { BsFillFuelPumpDieselFill } from 'react-icons/bs';
import { FaCarOn } from 'react-icons/fa6';
import { MdOutlineDateRange } from 'react-icons/md';
import { TbEngine } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

const MiniCart = ({ car }) => {
  const navigator = useNavigate();

  const handleDetails = id => {
    navigator(`/tableDetails/${id}`);
  };
  return (
    <div className="card w-full  glass shadow-md hover:shadow-2xl shadow-slate-500 cursor-pointer">
      {/* {car.isSold && ( */}
      {/* <div className="absolute top-7  -rotate-45 right-[67px] z-30 w-0 h-0">
        <svg
          className="w-14 h-14 fill-current text-white transform rotate-45"
          viewBox="0 0 10 10"
        >
          <polygon points="0,0 20,0 20,20" />
        </svg>
        <h1 className="z-40 absolute top-7 left-11 transform -translate-x-1/2 -translate-y-1/2 text-red-600 font-bold rotate-90">
          Sold
        </h1>
      </div> */}
      {/* )} */}
      <figure>
        <div className="relative w-full h-[200px] overflow-hidden">
          <img
            onClick={() => handleDetails(car._id)}
            className="object-cover w-full h-full transition-transform duration-300 transform hover:scale-110"
            src={car?.img1}
            alt="car"
          />
        </div>
        {/* <img className="w-full h-[200px]" src={car?.img1} alt="car!" /> */}
      </figure>
      <div className="p-2 pb-1">
        <h2 className="card-title  text-2xl text-indigo-900">
          {car?.brandName}{' '}
          {car?.sold && <span className="text-xl ml-5">[🆂🅾🅻🅳]</span>}
        </h2>
        <h2 className=" pb-2 text-lg text-slate-800 font-robot">{car?.name}</h2>

        <div className="flex gap-8 text-xs font-robot">
          <div>
            <div className="flex justify-center text-2xl mb-1 text-blue-900">
              <TbEngine />
            </div>
            <h1 className="font-normal"> {car?.engin}</h1>
          </div>
          <div>
            <div className="flex justify-center text-2xl mb-1 text-lime-900">
              <BsFillFuelPumpDieselFill />
            </div>
            <h1> {car?.fuel}</h1>
          </div>
          <div>
            <div className="flex justify-center text-2xl mb-1 text-orange-900">
              <FaCarOn />
            </div>
            <h1 className="font-normal"> {car?.mileage}</h1>
          </div>
          <div>
            <div className="flex justify-center text-2xl mb-1 text-slate-900">
              <MdOutlineDateRange />
            </div>
            <h1 className="font-normal"> {car?.year}</h1>
          </div>
        </div>

        <div className="mt-2 font-robot">
          {car.offerPrice ? (
            <>
              {car.offerPrice === '0' ? (
                <h1 className="font-semibold text-indigo-800 text-lg">
                  $ {car?.price}
                </h1>
              ) : (
                <div className="flex gap-5 items-center">
                  <h1 className="font-semibold text-indigo-800 text-lg">
                    $ {car.offerPrice}
                  </h1>
                  <h1 className="line-through text-red-700">$ {car.price}</h1>
                </div>
              )}
            </>
          ) : (
            <h1 className="font-semibold text-indigo-800 text-lg">
              $ {car?.price}
            </h1>
          )}
        </div>

        <h6
          onClick={() => handleDetails(car._id)}
          className="w-full text-lg font-normal py-1 mt-1"
        >
          Details
        </h6>
      </div>
    </div>
  );
};

export default MiniCart;
