import React from 'react';
import useAllBrand from '../../../hooks/useAllBrand';

const FilterSection = ({ brandName, setBrandName, handleClearAll }) => {
  const [brands] = useAllBrand();
  return (
    <div className="bg-slate-100 px-2 rounded-md pt-1">
      {brandName && (
        <div>
          <h1 className="text-sm font-semibold mt-3">
            Active Filter{' '}
            <span className="px-4 py-1 border-[1px] border-blue-500 rounded-md ml-2 text-blue-500">
              ?
            </span>
          </h1>
          <button
            onClick={() => {
              setBrandName('');
              handleClearAll();
            }}
            className="text-sm border-[1px] border-red-500 p-1 rounded-md mt-2"
          >
            {brandName} <span className="text-red-500 font-semibold">x</span>
          </button>
          <button onClick={handleClearAll} className="ml-3 text-sm">
            Clear All
          </button>
        </div>
      )}
      <h1 className="mt-5 font-semibold "> Filter By Makes</h1>
      {/* sec 1 */}
      <div className="h-[200px] md:h-[300px] overflow-y-auto">
        <p className="text-sm mb-3">Popular Makes</p>
        {/* Toyota */}
        {brands.map(brand => (
          <div
            onClick={() => setBrandName(brand?.name)}
            className={` ${
              brandName === brand?.name ? 'text-red-500' : 'text-black '
            } flex justify-between items-center`}
          >
            <label className="label cursor-pointer">
              <input
                type="radio"
                name="radio-10"
                className="radio checked:bg-red-500 h-5 w-5"
                checked={brandName === brand?.name}
              />
              <h1 className=" ml-3  font-semibold text-sm">{brand?.name} </h1>
            </label>
            <img className="w-6 h-6 mr-2" src={brand?.img} alt={brand?.name} />
          </div>
        ))}

        {/* Toyota
        <div
          onClick={() => setBrandName('Toyota')}
          className={` ${
            brandName === 'Toyota' ? 'text-red-500' : 'text-black '
          } flex justify-between`}
        >
          <label className="label cursor-pointer">
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-red-500 h-5 w-5"
              checked
            />
            <h1 className=" ml-3  font-semibold text-sm">Toyota </h1>
          </label>
          <img
            className="w-6 h-6 mr-2"
            src="https://purepng.com/public/uploads/large/purepng.com-toyota-car-logologocar-brand-logoscarstoyota-car-logo-1701527428948tj6m0.png"
            alt=""
          />
        </div> */}
        {/* Subaru */}
        {/* <div
          onClick={() => setBrandName('Subaru')}
          className={` ${
            brandName === 'Subaru' ? 'text-red-500' : 'text-black '
          } flex justify-between`}
        >
          <label className="label cursor-pointer">
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-red-500 h-5 w-5"
              checked
            />
            <h1 className=" ml-3  font-semibold text-sm">Subaru </h1>
          </label>
          <img
            className="w-6 h-6 mr-2"
            src="https://pngimg.com/d/car_logo_PNG1669.png"
            alt=""
          />
        </div> */}
        {/* Mazda */}
        {/* <div
          onClick={() => setBrandName('Mazda')}
          className={` ${
            brandName === 'Mazda' ? 'text-red-500' : 'text-black '
          } flex justify-between`}
        >
          <label className="label cursor-pointer">
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-red-500 h-5 w-5"
              checked
            />
            <h1 className=" ml-3  font-semibold text-sm">Mazda </h1>
          </label>
          <img
            className="w-6 h-6 mr-2"
            src="https://logos-world.net/wp-content/uploads/2020/05/Mazda-Logo-2018-present.png"
            alt=""
          />
        </div> */}
        {/*Honda  */}
        {/* <div
          onClick={() => setBrandName('Honda')}
          className={` ${
            brandName === 'Honda' ? 'text-red-500' : 'text-black '
          } flex justify-between`}
        >
          <label className="label cursor-pointer">
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-red-500 h-5 w-5"
              checked
            />
            <h1 className=" ml-3  font-semibold text-sm">Honda </h1>
          </label>
          <img
            className="w-6 h-6 mr-2"
            src="https://freepngimg.com/convert-png/36643-honda-logo"
            alt=""
          />
        </div> */}
        {/* Mitsubishi */}
        {/* <div
          onClick={() => setBrandName('Mitsubishi')}
          className={` ${
            brandName === 'Mitsubishi' ? 'text-red-500' : 'text-black '
          } flex justify-between`}
        >
          <label className="label cursor-pointer">
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-red-500 h-5 w-5"
              checked
            />
            <h1 className=" ml-3  font-semibold text-sm">Mitsubishi </h1>
          </label>
          <img
            className="w-6 h-6 mr-2"
            src="https://pngimg.com/d/car_logo_PNG1656.png"
            alt=""
          />
        </div> */}
        {/* Nissan */}
        {/* <div
          onClick={() => setBrandName('Nissan')}
          className={` ${
            brandName === 'Nissan' ? 'text-red-500' : 'text-black '
          } flex justify-between`}
        >
          <label className="label cursor-pointer">
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-red-500 h-5 w-5"
              checked
            />
            <h1 className=" ml-3  font-semibold text-sm">Nissan </h1>
          </label>
          <img
            className="w-6 h-6 mr-2"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8c/Nissan_logo.png/897px-Nissan_logo.png"
            alt=""
          />
        </div> */}
        {/* suzuki */}
        {/* <div
          onClick={() => setBrandName('Suzuki')}
          className={` ${
            brandName === 'Suzuki' ? 'text-red-500' : 'text-black '
          } flex justify-between`}
        >
          <label className="label cursor-pointer">
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-red-500 h-5 w-5"
              checked
            />
            <h1 className=" ml-3  font-semibold text-sm">Suzuki </h1>
          </label>
          <img
            className="w-6 h-6 mr-2"
            src="https://pngimg.com/d/suzuki_PNG12291.png"
            alt=""
          />
        </div> */}
        {/* Hyundai */}
        {/* <div
          onClick={() => setBrandName('Hyundai')}
          className={` ${
            brandName === 'Hyundai' ? 'text-red-500' : 'text-black '
          } flex justify-between`}
        >
          <label className="label cursor-pointer">
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-red-500 h-5 w-5"
              checked
            />
            <h1 className=" ml-3  font-semibold text-sm">Hyundai </h1>
          </label>
          <img
            className="w-6 h-6 mr-2"
            src="https://i.pinimg.com/originals/46/2d/89/462d89c1c8b1516ca99f166badd6d965.png"
            alt=""
          />
        </div> */}
        {/* Volkswagen */}
        {/* <div
          onClick={() => setBrandName('Volkswagen')}
          className={` ${
            brandName === 'Volkswagen' ? 'text-red-500' : 'text-black '
          } flex justify-between`}
        >
          <label className="label cursor-pointer">
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-red-500 h-5 w-5"
              checked
            />
            <h1 className=" ml-3  font-semibold text-sm">Volkswagen </h1>
          </label>
          <img
            className="w-6 h-6 mr-2"
            src="https://cdn.iconscout.com/icon/free/png-256/free-volkswagen-51-202922.png"
            alt=""
          />
        </div> */}
        {/* Audi */}
        {/* <div
          onClick={() => setBrandName('Audi')}
          className={` ${
            brandName === 'Audi' ? 'text-red-500' : 'text-black '
          } flex justify-between`}
        >
          <label className="label cursor-pointer">
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-red-500 h-5 w-5"
              checked
            />
            <h1 className=" ml-3  font-semibold text-sm">Audi </h1>
          </label>
          <img
            className="w-6 h-6 mr-2"
            src="https://cdn.icon-icons.com/icons2/2402/PNG/512/audi_logo_icon_145842.png"
            alt=""
          />
        </div> */}
        {/*Kia */}
        {/* <div
          onClick={() => setBrandName('KIA')}
          className={` ${
            brandName === 'KIA' ? 'text-red-500' : 'text-black '
          } flex justify-between`}
        >
          <label className="label cursor-pointer">
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-red-500 h-5 w-5"
              checked
            />
            <h1 className=" ml-3  font-semibold text-sm">KIA </h1>
          </label>
          <img
            className="w-6 h-6 mr-2"
            src="https://png.monster/wp-content/uploads/2021/03/pngwing.com-9-cd78cc2a-370x208.png"
            alt=""
          />
        </div> */}
        {/* tesla */}
        {/* <div
          onClick={() => setBrandName('Tesla')}
          className={` ${
            brandName === 'Tesla' ? 'text-red-500' : 'text-black '
          } flex justify-between`}
        >
          <label className="label cursor-pointer">
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-red-500 h-5 w-5"
              checked
            />
            <h1 className=" ml-3  font-semibold text-sm">Tesla </h1>
          </label>
          <img
            className="w-6 h-6 mr-2"
            src="https://cdn.iconscout.com/icon/free/png-256/free-tesla-3215501-2673919.png"
            alt=""
          />
        </div> */}
        {/* Fort */}
        {/* <div
          onClick={() => setBrandName('Ford')}
          className={` ${
            brandName === 'Ford' ? 'text-red-500' : 'text-black '
          } flex justify-between`}
        >
          <label className="label cursor-pointer">
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-red-500 h-5 w-5"
              checked
            />
            <h1 className=" ml-3  font-semibold text-sm">Ford </h1>
          </label>
          <img
            className="w-6 h-6 mr-2"
            src="https://cdn.iconscout.com/icon/free/png-256/free-ford-1-202767.png"
            alt=""
          />
        </div> */}

        {/* Porsche */}
        {/* <div
          onClick={() => setBrandName('Porsche')}
          className={` ${
            brandName === 'Porsche' ? 'text-red-500' : 'text-black '
          } flex justify-between`}
        >
          <label className="label cursor-pointer">
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-red-500 h-5 w-5"
              checked
            />
            <h1 className=" ml-3  font-semibold text-sm">Porsche </h1>
          </label>
          <img
            className="w-6 h-6 mr-2"
            src="https://cdn.iconscout.com/icon/free/png-256/free-porsche-5-202881.png"
            alt=""
          />
        </div> */}

        {/*Peugeot  */}
        {/* <div
          onClick={() => setBrandName('Peugeot')}
          className={` ${
            brandName === 'Peugeot' ? 'text-red-500' : 'text-black '
          } flex justify-between`}
        >
          <label className="label cursor-pointer">
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-red-500 h-5 w-5"
              checked
            />
            <h1 className=" ml-3  font-semibold text-sm">Peugeot </h1>
          </label>
          <img
            className="w-6 h-6 mr-2"
            src="https://cdn.icon-icons.com/icons2/3914/PNG/512/peugeot_logo_icon_248870.png"
            alt=""
          />
        </div> */}
        {/* Mercedes-Benz */}
        {/* <div
          onClick={() => setBrandName('Mercedes-Benz')}
          className={` ${
            brandName === 'Mercedes-Benz' ? 'text-red-500' : 'text-black '
          } flex justify-between`}
        >
          <label className="label cursor-pointer">
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-red-500 h-5 w-5"
              checked
            />
            <h1 className=" ml-3  font-semibold text-sm">Mercedes-Benz </h1>
          </label>
          <img
            className="w-6 h-6 mr-2"
            src="https://cdn.iconscout.com/icon/free/png-256/free-mercedes-8-202855.png"
            alt=""
          />
        </div> */}

        {/*Aston Martin  */}
        {/* <div
          onClick={() => setBrandName('Aston Martin')}
          className={` ${
            brandName === 'Aston Martin' ? 'text-red-500' : 'text-black '
          } flex justify-between`}
        >
          <label className="label cursor-pointer">
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-red-500 h-5 w-5"
              checked
            />
            <h1 className=" ml-3  font-semibold text-sm">Aston Martin </h1>
          </label>
          <img
            className="w-6 h-6 mr-2"
            src="https://cdn.icon-icons.com/icons2/2402/PNG/512/aston_martin_logo_icon_145844.png"
            alt=""
          />
        </div> */}

        {/* Volvo */}
        {/* <div
          onClick={() => setBrandName('Volvo')}
          className={` ${
            brandName === 'Volvo' ? 'text-red-500' : 'text-black '
          } flex justify-between`}
        >
          <label className="label cursor-pointer">
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-red-500 h-5 w-5"
              checked
            />
            <h1 className=" ml-3  font-semibold text-sm">Volvo </h1>
          </label>
          <img
            className="w-6 h-6 mr-2"
            src="https://cdn.iconscout.com/icon/free/png-256/free-volvo-202923.png"
            alt=""
          />
        </div> */}

        {/* Land Rover */}
        {/* <div
          onClick={() => setBrandName('Land Rover')}
          className={` ${
            brandName === 'Land Rover' ? 'text-red-500' : 'text-black '
          } flex justify-between`}
        >
          <label className="label cursor-pointer">
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-red-500 h-5 w-5"
              checked
            />
            <h1 className=" ml-3  font-semibold text-sm">Land Rover </h1>
          </label>
          <img
            className="w-6 h-6 mr-2"
            src="https://seeklogo.com/images/L/land-rover-logo-E91233A586-seeklogo.com.png?v=638133639650000000"
            alt=""
          />
        </div> */}
        {/* Holden  */}
        {/* <div
          onClick={() => setBrandName('Holden')}
          className={` ${
            brandName === 'Holden' ? 'text-red-500' : 'text-black '
          } flex justify-between`}
        >
          <label className="label cursor-pointer">
            <input
              type="radio"
              name="radio-10"
              className="radio checked:bg-red-500 h-5 w-5"
              checked
            />
            <h1 className=" ml-3  font-semibold text-sm">Holden </h1>
          </label>
          <img
            className="w-6 h-6 mr-2"
            src="https://seeklogo.com/images/H/holden-logo-9961407980-seeklogo.com.png"
            alt=""
          />
        </div> */}
      </div>
      {/* sec 2 */}
      <div></div>
    </div>
  );
};

export default FilterSection;
