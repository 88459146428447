import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe(
  'pk_test_51L1qoRHptux7TSyCTlbI9nx3AjQiVgLugq7xrEXkN2vpYAvD7STzzybgZQ13nOWR8Nce678znV1MTjbv2rHawOI700nW883wJo'
);

const Payment = () => {
  const { id } = useParams();
  const [car, setCar] = useState({});
  useEffect(() => {
    fetch(`https://car-server-aus.onrender.com/buyCar/${id}`)
      .then(res => res.json())
      .then(data => setCar(data));
  }, [id]);

  const price = car?.car?.price;
  // const price2 = parseFloat(cPrice.toFixed(2));

  return (
    <div className="mx-20">
      <h1>dado {id}</h1>
      <h1>price : {car?.car?.price}</h1>
      <h1>price : {price}</h1>

      <Elements stripe={stripePromise}>
        <CheckoutForm price={price} />
      </Elements>
    </div>
  );
};

export default Payment;
