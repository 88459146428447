import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import ManageCar from './ManageCar';

const ManageCars = () => {
  const [cars, setCars] = useState([]);

  const navigator = useNavigate();

  useEffect(() => {
    fetch('https://car-server-aus.onrender.com/cars')
      .then(res => res.json())
      .then(data => setCars(data));
  }, [cars]);

  const handleEdit = id => {
    navigator(`editCar/${id}`);
  };
  const handleSold = (id, sold) => {
    console.log(id, sold);
    const updateSold = { sold: sold };
    fetch(`https://car-server-aus.onrender.com/sold/${id}`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(updateSold),
    })
      .then(res => res.json())
      .then(data => {
        Swal.fire({
          title: 'Custom animation with Animate.css',
          icon: 'success',
          title: 'Done',
          showConfirmButton: false,
          timer: 1500,
          showClass: {
            popup: `
      animate__animated
      animate__fadeInUp
      animate__faster
    `,
          },
          hideClass: {
            popup: `
      animate__animated
      animate__fadeOutDown
      animate__faster
    `,
          },
        });
      });
  };

  const handleDelete = id => {
    const proceed = window.confirm('Are You Sure ?');
    if (proceed) {
      const url = `https://car-server-aus.onrender.com/deleteCar/${id}`;
      fetch(url, {
        method: 'DELETE',
      })
        .then(res => res.json())
        .then(data => {
          const remaining = cars.filter(product => product._id !== id);
          setCars(remaining);
          toast.success('Successfully Delete');
        });
    }
  };
  return (
    <div className="mb-20 w-[310px] md:w-full">
      <div className="overflow-x-auto p-2">
        <table className="table w-[300px] md:w-full">
          {/* head */}
          <thead>
            <tr className="text-center">
              <th className="text-sm">Index</th>
              <th className="text-sm">Image</th>
              <th className="text-sm">Brand</th>
              <th className="text-sm">Name</th>
              <th className="text-sm">Price</th>
              <th className="text-sm">Edit</th>
              <th className="text-sm">Sold</th>
              <th className="text-sm">Delete</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {cars
              .slice()
              .reverse()
              .map((car, index) => (
                <ManageCar
                  key={car._id}
                  car={car}
                  index={index + 1}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  handleSold={handleSold}
                ></ManageCar>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageCars;
