import React from 'react';
import { FaFacebook, FaInstagramSquare } from 'react-icons/fa';
import joky from '../../../../Images/Logo/joky.jpg';

const Contact = () => {
  return (
    <div className="bg-gradient-to-t bg-rose-200 text-black mt-20 md:mt-52">
      <div className="mx-5  md:mx-20 md:grid md:grid-cols-2 ">
        <div className="p-4 ">
          <h1 className="py-4 text-2xl font-bold font-robot">
            Can't find the service you want? Contact us 24/6 <br /> with
            0423265387 ...
          </h1>
          <div>
            <div className="flex items-center ml-3 font-robot">
              <img
                className="h-10 w-10 mr-2"
                src="https://media1.giphy.com/media/ThFUxEEXQEmYN7aePZ/giphy.gif?cid=6c09b9527c53dg8hnc43qacq1zgxre1og7tbzq18wnsiu04p&ep=v1_stickers_related&rid=giphy.gif&ct=s"
                alt=""
              />
              <a href="tel:0423265387">0423265387</a>
            </div>
            <div className="flex items-center ml-5 mt-1 gap-x-2">
              <img
                className="h-5 w-5 mr-2"
                src="https://www.impactatkay.com/images/contact.gif"
                alt=""
              />
              <p>rosemotors.ptyltd@gmail.com</p>
            </div>
            <a
              className="flex items-center hover:underline hover:text-blue-600"
              href="https://maps.app.goo.gl/wVHPurzpJm3nQttF6"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="h-14 w-14  "
                src="https://cdn.pixabay.com/animation/2023/04/06/16/10/16-10-43-442_512.gif"
                alt=""
              />
              <p className="font-robot">
                61 Parramatta Road, Five Dock , NSW 2046
              </p>
            </a>
          </div>
          <div className="flex ml-5 gap-5 items-center">
            <a
              href="https://www.facebook.com/rosemotorsptyltd?mibextid=2JQ9oc"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebook className="text-3xl text-blue-800 animate-bounce" />
            </a>
            <a
              href="https://www.facebook.com/rosemotorsptyltd?mibextid=2JQ9oc"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagramSquare className="text-3xl text-orange-700 animate-bounce" />
            </a>
          </div>
        </div>
        <div className="flex justify-center mt-36 md:mt-0">
          <div className="">
            <div className=" flex justify-center ">
              <img
                style={{ marginTop: '-120px' }}
                className="h-64 w-64 rounded-full bg-primary pb-[7px] pl-[7px] pr-[7px]"
                src={joky}
                alt=""
              />
            </div>
            <h1 className="text-center  text-2xl font-semibold mt-2">JOKY</h1>
            <p className="text-start text-sm font-semibold">
              Rose Motors imports and sells quality low kilometer cars, vans and
              vehicles direct from Japan We also stock comestic model cars a!'
              at highly competitive prices. We have Toyota, Nissan, Mitsubishi,
              Honda and many other. makes and models Petrol Diesel ,Hybird and
              battery operated vehicle lf we don't have a vehicle currently in
              stock we can source one for you, so please contact us today to see
              what we can do for you.
            </p>
            <div className="flex justify-center mt-2 animate-bounce">
              <p className="bg-primary px-2 py-1 text-white rounded font-semibold ">
                TRADE - IN,FINANCE AND WARRANTY AVAILABLE !!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
