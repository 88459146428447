import React from 'react';
import Banner from './Banner';

const HappyClient = () => {
  return (
    <div className="mt-32  md:mx-20 pb-5 shadow-md shadow-lime-200  rounded-lg pt-3 px-4">
      <h1 className="text-2xl font-semibold mt-2 text-lime-900">
        SOME HAPPY FACES
      </h1>
      <h1 className="text-4xl font-semibold mb-3 mt-3 text-indigo-900">
        Happy customers, real stories
      </h1>
      <div className="mt-5">
        <Banner />
      </div>
    </div>
  );
};

export default HappyClient;
