import React, { useEffect, useState } from 'react';
import { FaPlusSquare } from 'react-icons/fa';
import { toast } from 'react-toastify';
import AddHappyClient from './AddHappyClient';
import HappyClient from './HappyClient';

const HappyClients = () => {
  const [happyClients, setHappyClients] = useState([]);

  useEffect(() => {
    fetch(`https://car-server-aus.onrender.com/happyClient`)
      .then(res => res.json())
      .then(data => setHappyClients(data));
  }, [happyClients]);
  const handleDelete = id => {
    const proceed = window.confirm('Are You Sure ?');
    if (proceed) {
      const url = `https://car-server-aus.onrender.com/happyClient/${id}`;
      fetch(url, {
        method: 'DELETE',
      })
        .then(res => res.json())
        .then(data => {
          const remaining = happyClients.filter(product => product._id !== id);
          setHappyClients(remaining);
          toast.success('Successfully Delete');
        });
    }
  };
  return (
    <div className="w-[320px] md:w-[1000px] lg:w-[1200px]">
      {/* add client */}
      <div className="col-span-1 flex items-center pt-4 ml-2 justify-end">
        <label
          htmlFor="my_modal_7"
          className="cursor-pointer flex btn gap-2 text-white"
        >
          <FaPlusSquare className="text-2xl " /> Add Happy Client
        </label>
        <input type="checkbox" id="my_modal_7" className="modal-toggle" />
        <div className="modal" role="dialog">
          <div className="modal-box">
            <AddHappyClient />
          </div>
        </div>
      </div>
      {/* show */}
      <div className="m-2 md:mx-10">
        <h1 className="text-center text-3xl font-bold my-3 text-slate-700">
          Happy Client{' '}
        </h1>
        <div className="grid grid-cols-12 text-center text-xs md:text-2xl font-semibold text-indigo-900">
          <div className="col-span-3 border-[1px] border-r-0 border-indigo-900 px-4 py-2 ">
            <h1>Image</h1>
          </div>
          <div className="col-span-4 md:col-span-3 border-[1px] border-r-0 border-indigo-900 px-4 py-2 ">
            <h1>Name</h1>
          </div>
          <div className="col-span-3 md:col-span-5 border-[1px] border-r-0 border-indigo-900 md:px-4 py-2 ">
            <h1>Description</h1>
          </div>
          <div className="col-span-2 md:col-span-1 border-[1px]  border-indigo-900  py-2 ">
            <h1>Delete</h1>
          </div>
        </div>
        {happyClients
          .slice()
          .reverse()
          .map((client, index) => (
            <HappyClient
              key={client._id}
              client={client}
              index={index + 1}
              handleDelete={handleDelete}
            />
          ))}
      </div>
    </div>
  );
};

export default HappyClients;
