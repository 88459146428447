import React from 'react';
import { FaEdit, FaUndo } from 'react-icons/fa';

const ManageCar = ({ index, car, handleEdit, handleDelete, handleSold }) => {
  // console.log(car);
  return (
    <tr className="font-bold text-sm">
      <td>{index}</td>
      <td>
        <img className="w-16 h-12 rounded-xl" src={car?.img1} alt="" />
      </td>
      <td>{car?.brandName}</td>
      <td>{car?.name}</td>
      <td>
        {car.offerPrice ? (
          <>
            {car.offerPrice === '0' ? (
              <h1 className="font-semibold text-indigo-800 text-lg">
                $ {car?.price}
              </h1>
            ) : (
              <div className="">
                <h1 className="font-semibold text-indigo-800 text-lg">
                  $ {car.offerPrice}
                </h1>
                <h1 className="line-through text-red-700">$ {car.price}</h1>
              </div>
            )}
          </>
        ) : (
          <h1 className="font-semibold text-indigo-800 text-lg">
            $ {car?.price}
          </h1>
        )}
      </td>

      <td className="">
        <button onClick={() => handleEdit(car?._id)}>
          <FaEdit className="text-2xl text-neutral cursor-pointer hover:text-primary" />
        </button>
      </td>
      <td className=" text-center">
        {car.sold ? (
          <div className="flex gap-1 -mr-8">
            <h1 className="text-green-700">Solled</h1>
            <button
              onClick={() => handleSold(car?._id, false)}
              className="ml-2"
            >
              <FaUndo />
            </button>
          </div>
        ) : (
          <button
            onClick={() => handleSold(car?._id, true)}
            className="btn btn-success btn-xs"
          >
            Sold
          </button>
        )}
      </td>

      <td>
        <button onClick={() => handleDelete(car?._id)} className="w-20 h-10">
          <img
            src="https://app.tryzulu.com/assets/static/images/delete-gif-dark-mode.gif"
            alt=""
          />
        </button>
      </td>
    </tr>
  );
};

export default ManageCar;
