import Aos from 'aos';
import 'aos/dist/aos.css';
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import CreateAccount from './Components/Login/CreateAccount';
import Login from './Components/Login/Login';
import RequireAuth from './Components/Login/RequireAUth';
import RequireAdmin from './Components/Login/RequireAdmin';
import About from './Components/Pages/About/About';
import BuyCar from './Components/Pages/BuyCar/BuyCar';
import ShowBanners from './Components/Pages/Dashboard/AddCar/AddBanner/ShowBanners';
import AddCar from './Components/Pages/Dashboard/AddCar/AddCar';
import CarBookings from './Components/Pages/Dashboard/CarBooking/CarBookings';
import Dashboard from './Components/Pages/Dashboard/Dashboard';
import DashboardIndex from './Components/Pages/Dashboard/DashboardIndex/DashboardIndex';
import HappyClients from './Components/Pages/Dashboard/HappyClient/HappyClients';
import EditCars from './Components/Pages/Dashboard/ManageCar/EditCars';
import ManageCars from './Components/Pages/Dashboard/ManageCar/ManageCars';
import EditProfile from './Components/Pages/Dashboard/Profile/EditProfile';
import Profile from './Components/Pages/Dashboard/Profile/Profile';
import Users from './Components/Pages/Dashboard/User/Users';
import BrandFilter from './Components/Pages/Home/Banner/Brand Filter/BrandFilter';
import PriceFilter from './Components/Pages/Home/Banner/PriceFilter.js/PriceFilter';
import MiniCarts from './Components/Pages/Home/Cart/MiniCart/MiniCarts';
import TableDetails from './Components/Pages/Home/Cart/TableDetails';
import Home from './Components/Pages/Home/Home';
import MyBookingCars from './Components/Pages/MyBookingCar/MyBookingCars';
import Payment from './Components/Pages/MyBookingCar/Payment/Payment';
import Navbar from './Components/Share/Navbar';
import NotFound from './Components/Share/NotFound';
Aos.init();

function App() {
  const [searchGet, setSearchGet] = useState([]);
  const [bodyTypes, setBodyType] = useState('');
  const [priceFilter, setPriceFilter] = useState([]);
  const [brandName, setBrandName] = useState([]);

  return (
    <div className="font-serif">
      {/* <CreateAccount /> */}
      <div className="z-50 fixed top-0 right-0 left-0  w-full">
        <Navbar setSearchGet={setSearchGet} />
      </div>

      <Routes>
        <Route
          path="/"
          element={
            <Home
              searchGet={searchGet}
              setSearchGet={setSearchGet}
              setBodyType={setBodyType}
              setPriceFilter={setPriceFilter}
              setBrandName={setBrandName}
            />
          }
        ></Route>
        <Route path="/about" element={<About />}></Route>
        <Route
          path="/priceFilter"
          element={<PriceFilter priceFilter={priceFilter} />}
        ></Route>
        <Route
          path="/brandFilter"
          element={<BrandFilter brandName={brandName} />}
        ></Route>
        <Route path="/tableDetails/:id" element={<TableDetails />}></Route>
        <Route
          path="/buyCar/:id"
          element={
            <RequireAuth>
              <BuyCar />
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/payment/:id"
          element={
            <RequireAuth>
              <Payment />
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/profile"
          element={
            <RequireAuth>
              <Profile />
            </RequireAuth>
          }
        />
        <Route
          path="editProfile"
          element={
            <RequireAuth>
              <EditProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/myBookingCar"
          element={
            <RequireAuth>
              <MyBookingCars />
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/rent"
          element={<RequireAuth>{/* <Rents /> */}</RequireAuth>}
        ></Route>
        <Route
          path="/miniCart"
          element={<MiniCarts bodyTypes={bodyTypes} />}
        ></Route>
        <Route path="/createAccount" element={<CreateAccount />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/*" element={<NotFound />}></Route>

        {/* dashboard Start */}
        <Route
          path="/dashboard"
          element={
            <RequireAuth>
              <RequireAdmin>
                <Dashboard />
              </RequireAdmin>
            </RequireAuth>
          }
        >
          <Route index element={<DashboardIndex />} />

          <Route path="index" element={<DashboardIndex />} />
          <Route path="profile" element={<Profile />} />
          <Route path="editProfile" element={<EditProfile />} />
          <Route path="carBooking" element={<CarBookings />} />
          <Route path="addCar" element={<AddCar />} />
          <Route path="addBanner" element={<ShowBanners />} />
          {/* <Route path="addRent" element={<RentCar />} /> */}
          <Route path="users" element={<Users />} />
          <Route path="happyClient" element={<HappyClients />} />
          <Route path="manageCar" element={<ManageCars />} />
          <Route path="manageCar/editCar/:id" element={<EditCars />} />
          {/*  <Route path="manageBooking" element={<ManageBookings />} />
          <Route path="contact" element={<Contacts />} /> */}
        </Route>
        {/* dashboard end */}
      </Routes>

      <ToastContainer />
    </div>
  );
}

export default App;
