import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './CartDetails.css';

// import required modules
import { Navigation, Pagination } from 'swiper/modules';

const DetailsCarousel = ({ car }) => {
  return (
    <>
      <Swiper
        pagination={{
          type: 'fraction',
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={car?.img1} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={car?.img2} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={car?.img3} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={car?.img4} alt="" />
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default DetailsCarousel;
