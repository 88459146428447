import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate, useLocation } from 'react-router-dom';
import auth from '../../firebase.init';
import Loading from '../Share/Loading';

const RequireAdmin = ({ children }) => {
  const [user, loading] = useAuthState(auth);
  const [users, setUsers] = useState([]);
  const location = useLocation();

  useEffect(() => {
    fetch(`https://car-server-aus.onrender.com/user/${user?.email}`)
      .then(res => res.json())
      .then(data => setUsers(data));
  }, [users]);

  if (loading) {
    return <Loading></Loading>;
  }

  if (user && users[0]?.admin === false) {
    return <Navigate to="/login" state={{ from: location }} replace></Navigate>;
  }
  return children;
};

export default RequireAdmin;
