import React, { useEffect } from 'react';
import { FaHandPointDown, FaHandshake } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import Footer from '../../Share/Footer';

const About = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="bg-white">
      <div className="pt-20 px-3 md:px-20 pb-8">
        <div className="container mx-auto">
          <section className="my-8">
            <h2 className="text-4xl font-bold text-center text-indigo-900">
              Welcome to Our Rose Motors
            </h2>
            <p className="mt-4 text-xl mb-2 text-center font-semibold">
              Our Latest Car
            </p>

            <div className="bg-red-400 flex justify-center rounded-lg">
              <img
                src="https://assets.fastly.carvana.io/home-assets/nba/mustang.png"
                alt=""
              />
            </div>
            <h1 className="mt-2 text-center">
              We believe in providing personalized service and exceeding our
              customers' expectations at every turn. We invite you to contact us
              today and experience the Rose Motors difference. Let us help you
              find the perfect vehicle to meet your needs and preferences. Your
              satisfaction is our mission, and we're committed to delivering
              excellence in every aspect of our business.
            </h1>
          </section>

          {/* Mission Statement */}
          <section className="my-8">
            <h2 className="text-3xl font-bold text-center text-indigo-900 flex items-center gap-5">
              Our Mission{' '}
              <FaHandPointDown className="text-orange-700 animate-bounce" />
            </h2>
            <p className="mt-4 text-lg text-start">
              Our Mission at Rose Motors is to provide our customers with access
              to top-quality, low kilometer vehicles imported directly from
              Japan. We pride ourselves on offering a diverse selection of cars,
              vans, and vehicles, including popular makes and models such as
              Toyota, Nissan, Mitsubishi, and Honda. In addition to our imported
              vehicles, we also maintain a stock of domestic model cars at
              highly competitive prices, ensuring that our customers have plenty
              of options to choose from. Whether you're in the market for a
              petrol, diesel, hybrid, or battery-operated vehicle, we have
              something to suit every preference and need. At Rose Motors,
              customer satisfaction is our top priority. If we don't currently
              have the vehicle you're looking for in our inventory, our
              dedicated team will go the extra mile to source it for you. We
              believe in providing personalized service and exceeding our
              customers' expectations at every turn. We invite you to contact us
              today and experience the Rose Motors difference. Let us help you
              find the perfect vehicle to meet your needs and preferences. Your
              satisfaction is our mission, and we're committed to delivering
              excellence in every aspect of our business.
            </p>
          </section>

          {/* Team Section */}
          {/* <section className="my-8">
            <h2 className="text-3xl font-bold text-center">Owner</h2>
            <div className="flex justify-center">
              <img
                className="h-[400px] rounded-md mt-3"
                src="http://localhost:3000/static/media/joky.5037d5178a9f859e6ac3.jpg"
                alt=""
              />
            </div>
          </section> */}

          {/* Testimonials Section */}
          <section className="my-8">
            <h2 className="text-3xl font-bold text-center text-orange-900  flex items-center gap-5 ">
              Testimonials{' '}
              <FaHandshake className="text-indigo-800 animate-bounce" />
            </h2>
            <div className="mt-4">
              <h1 className="text-start text-lg">
                Rose Motors provided an exceptional car buying experience for
                me. Their extensive selection of low kilometer vehicles imported
                from Japan made it easy for me to find exactly what I was
                looking for. The quality of the cars, vans, and vehicles is
                top-notch, and the competitive prices sealed the deal for me. I
                was impressed by their diverse range of makes and models,
                including Toyota, Nissan, Mitsubishi, and Honda, among others.
                Whether you're in the market for a petrol, diesel, hybrid, or
                battery-operated vehicle, Rose Motors has something for
                everyone. What truly stood out to me was their commitment to
                customer satisfaction. Even if they don't have a specific
                vehicle in stock, they go above and beyond to source it for you.
                Their dedication to meeting the needs of their customers is
                unparalleled. Overall, I highly recommend Rose Motors to anyone
                in search of a quality vehicle at a competitive price. Their
                professionalism, expertise, and personalized service make them
                the go-to destination for all your car-buying needs.
              </h1>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
