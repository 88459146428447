import React from 'react';

const CarBooking = ({ car, handleRemove, handleDelivered, index }) => {
  return (
    <tr className="text-xs">
      <td>{index}</td>
      <td>
        <div className="w-20 h-14">
          {' '}
          <img className="h-14 w-full rounded-lg" src={car?.car?.img1} alt="" />
        </div>
      </td>
      <td>{car?.car?.name}</td>
      <td> {car?.name}</td>
      <td className="font-robot"> {car?.phone}</td>
      <td>{car?.address}</td>
      <td className="font-robot ">$ {car?.car?.price}</td>
      <td className="font-robot"> {car?.date}</td>
      <td className="font-robot"> {car?.time}</td>
      {/* <td>
        {car?.payment ? (
          <h1 className="font-semibold text-green-500">Paid</h1>
        ) : (
          <h1 className="font-semibold text-red-500">Unpaid</h1>
        )}
      </td> */}
      <td>
        {car.delivered ? (
          <h1 className="font-semibold text-green-600 text-sm">Delivered</h1>
        ) : (
          <button
            onClick={() => handleDelivered(car?._id)}
            className="btn btn-xs btn-warning text-white"
          >
            Pending
          </button>
        )}
      </td>
      <td>
        <button
          onClick={() => handleRemove(car?._id)}
          className="btn btn-xs btn-primary text-white"
        >
          Remove
        </button>
      </td>
    </tr>
  );
};

export default CarBooking;
