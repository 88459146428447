import { signOut } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { FaSearch } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../Images/Logo/rose.png';
import auth from '../../firebase.init';

const Navbar = ({ setSearchGet }) => {
  const [user] = useAuthState(auth);
  const email = user?.email;
  const navigate = useNavigate();
  const [booking, setBooking] = useState([]);
  const [heart, setHeart] = useState();
  const [search, setSearch] = useState(false);
  const [dbUser, setDbUser] = useState([]);

  const [cars, setCars] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    fetch(`https://car-server-aus.onrender.com/cars`)
      .then(res => res.json())
      .then(data => {
        setLoading(false);
        setCars(data);
      });
  }, []);
  useEffect(() => {
    fetch(`https://car-server-aus.onrender.com/user/${email}`)
      .then(res => res.json())
      .then(data => setDbUser(data));
  }, [dbUser, email]);

  const handleSearch = e => {
    const query = e.target.value;
    setSearchQuery(query);

    // Filter cards based on search query
    const filtered = cars.filter(car => {
      // Check if the car has a 'name' property before using toLowerCase()
      return car.name && car.name.toLowerCase().includes(query.toLowerCase());
    });

    setSearchGet(filtered);
  };

  const logout = () => {
    signOut(auth);
  };

  useEffect(() => {
    fetch(`https://car-server-aus.onrender.com/carBooking/${email}`)
      .then(res => res.json())
      .then(data => setBooking(data));
  }, [booking, email]);

  const handleBook = () => {
    navigate('/myOrders');
  };

  return (
    <div className="  navbar  bg-primary  text-black ">
      <div className="navbar-start ">
        <div className="dropdown">
          <ul
            tabIndex="0"
            className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52 text-black "
          >
            {/* <h1>dado</h1> */}
          </ul>
        </div>
        <Link
          to="/"
          className=" normal-case font-bold lg:text-3xl  sm:text-sm text-amber-500 md:ml-7 bg-white rounded-lg"
        >
          <img className="h-8 w-124 md:h-12 mr-2 " src={logo} alt="" />
        </Link>
      </div>
      <div className="navbar-center hidden lg:flex lg:pr-36 ml-40">
        <ul className="menu menu-horizontal p-0">{/* <h1>Dado1</h1> */}</ul>
      </div>
      {/* Image */}
      <div className="navbar-end md:mr-10">
        <div className="flex md:mr-3">
          {search && (
            <input
              value={searchQuery}
              onChange={handleSearch}
              className=" w-[100px] md:w-[300px] lg:w-[550px] bg-slate-100 rounded rounded-r-none pl-1 md:pl-2 py-2  border-[1px] border-slate-300 transition  duration-1000"
              placeholder="Search Cars"
              type="text"
            />
          )}
          {search ? (
            <button
              onClick={() => setSearch(false)}
              className=" px-2 md:px-3 rounded-r-md font-semibold text-white text-xl"
            >
              <ImCross className="text-white text-xs md:text-xl " />
            </button>
          ) : (
            <button
              onClick={() => setSearch(true)}
              className=" px-2 md:px-3 rounded-r-md"
            >
              <FaSearch className="text-white text-xs md:text-xl " />
            </button>
          )}
        </div>

        {search ? (
          <></>
        ) : (
          <Link to="/about" className="mx-3  md:ml-7 text-white">
            About
          </Link>
        )}
        <div className="">
          {user ? (
            // <button
            //   className=" font-bold text-orange-500 w-[100px] -ml-[20px] md:w-[100px] text-xs md:ml-[20px]"
            //   onClick={logout}
            // >
            //   Sign Out
            // </button>
            <div className="dropdown dropdown-end  md:mr-5">
              <label tabindex="0" className="btn btn-ghost btn-circle avatar">
                <div className="w-10  rounded-full">
                  {/* <img src="https://placeimg.com/80/80/people" alt="" /> */}
                  {dbUser[0]?.image ? (
                    <img className="h-6" src={dbUser[0]?.image} alt="" />
                  ) : (
                    <img
                      className="h-6"
                      src="https://cdn.imgbin.com/6/25/24/imgbin-user-profile-computer-icons-user-interface-mystique-aBhn3R8cmqmP4ECky4DA3V88y.jpg"
                      alt=""
                    />
                  )}
                </div>
              </label>
              <ul
                tabindex="0"
                className="menu menu-compact dropdown-content mt-3 p-2 shadow rounded-box w-40 bg-white border-2 border-gray-700 hover:bg-purple-100 shadow-2xl"
              >
                <li>
                  <Link to="/myBookingCar">My Booking</Link>
                </li>
                {/* <li>
                    <Link to="/rent">Rent</Link>
                  </li> */}

                {/* <li>
                  <Link to="/profile">Profile</Link>
                </li> */}
                <li>
                  <Link to="/editProfile">Profile </Link>
                </li>
                <li>
                  {dbUser[0]?.admin === true && (
                    <Link
                      className="text-red-600 font-semibold hover:text-orange-600 text-sm mr-[10px]"
                      to="/dashboard"
                    >
                      Dashboard
                    </Link>
                  )}
                </li>
                <li className=" font-bold ">
                  {user ? (
                    <button
                      className=" font-bold  text-orange-500"
                      onClick={logout}
                    >
                      Sign Out
                    </button>
                  ) : (
                    <Link to="/login">Login</Link>
                  )}
                </li>
              </ul>
            </div>
          ) : (
            <div>
              <Link
                className="hover:text-orange-300 text-white text-xs ml-[5px] md:ml-[20px]"
                to="/login"
              >
                Login
              </Link>
              <Link
                className="pl-2 text-white hover:text-orange-300 text-xs"
                to="/createAccount"
              >
                <span className="mr-1">/</span>Register
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
