import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import User from './User';

const Users = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetch(`https://car-server-aus.onrender.com/user`)
      .then(res => res.json())
      .then(data => setUsers(data));
  }, [users]);

  const handleAdmin = (id, admin) => {
    const updateAdmin = { admin: admin };
    fetch(`https://car-server-aus.onrender.com/admin/${id}`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(updateAdmin),
    })
      .then(res => res.json())
      .then(data => {
        Swal.fire({
          title: 'Custom animation with Animate.css',
          icon: 'success',
          title: 'Done',
          showConfirmButton: false,
          timer: 1500,
          showClass: {
            popup: `
      animate__animated
      animate__fadeInUp
      animate__faster
    `,
          },
          hideClass: {
            popup: `
      animate__animated
      animate__fadeOutDown
      animate__faster
    `,
          },
        });
      });
  };
  return (
    <div className="mx-1 w-[320px] md:w-[1000px] md:mx-20 mt-5">
      <div className="overflow-x-auto shadow-xl rounded-lg">
        <table className="table  w-full text-center ">
          {/* head */}
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th>Name</th>
              <th>Email</th>
              <th>Add admin</th>
              <th>remove admin</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <User
                key={user._id}
                user={user}
                index={index + 1}
                handleAdmin={handleAdmin}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Users;
