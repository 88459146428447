import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaPlusSquare, FaRegEye } from 'react-icons/fa';
import Swal from 'sweetalert2';
import useAllBodyTypes from '../../../hooks/useAllBodyTypes';
import useAllBrand from '../../../hooks/useAllBrand';
import AddAd from './Add Ad/AddAd';
import ShowAd from './Add Ad/ShowAd';
import AltAddCar from './AltAddCar';
import AddBodyType from './Body Types/AddBodyType';
import ShowBodyType from './Body Types/ShowBodyType';
import AddBrandName from './Brand Name/AddBrandName';
import ShowBrandName from './Brand Name/ShowBrandName';

const AddCar = () => {
  const [brandName, setCarBrand] = useState('');
  const [bodyType, setBodyType] = useState('');
  const [alt, setAlt] = useState(false);
  const imageHostKey = '39899c0cdbfbe66a2dbde3818a91832c';
  const [loading, setLoading] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  // brand
  const [brands] = useAllBrand();
  // body type
  const [bodyTypes] = useAllBodyTypes();

  const onSubmit = async data => {
    setLoading(true);
    try {
      const image1 = data.image1[0];
      const image2 = data.image2[0];
      const image3 = data.image3[0];
      const image4 = data.image4[0];

      const formData = new FormData();
      formData.append('image', image1);

      const url = `https://api.imgbb.com/1/upload?expiration=600&key=${imageHostKey}`;
      const response1 = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      const imageData1 = await response1.json();
      const img1 = imageData1.data.url;

      formData.delete('image'); // Remove the previous image
      formData.append('image', image2);

      const response2 = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      const imageData2 = await response2.json();
      const img2 = imageData2.data.url;

      formData.delete('image'); // Remove the previous image
      formData.append('image', image3);

      const response3 = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      const imageData3 = await response3.json();
      const img3 = imageData3.data.url;

      formData.delete('image'); // Remove the previous image
      formData.append('image', image4);

      const response4 = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      const imageData4 = await response4.json();
      const img4 = imageData4.data.url;

      const changeUrl = {
        brandName,
        bodyType,
        img1,
        img2,
        img3,
        img4,
        ...data,
      };

      // console.log(changeUrl);

      const serverResponse = await fetch(
        'https://car-server-aus.onrender.com/cars',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(changeUrl),
        }
      );

      const serverData = await serverResponse.json();
      setLoading(false);
      Swal.fire({
        icon: 'success',
        title: 'Successful Add A Car',
        showConfirmButton: false,
        timer: 1500,
      });
      // console.log('Images uploaded successfully:', serverData);
      reset();
    } catch (error) {
      // Handle error
      // console.error('Error uploading images:', error);
    }
  };
  const handleClick = () => {
    setAlt(prevState => !prevState);
  };
  return (
    <div className="w-[320px] md:w-full">
      <div className="flex  justify-center items-center">
        <div>
          <h1 className="mx-1  md:mx-10 lg:mx-20 pt-5 text-4xl text-center font-semibold text-red-500 mb-5">
            Add Cars{' '}
            <button
              onClick={handleClick}
              className="text-slate-300 text-sm ml-10"
            >
              {alt ? 'Alt' : 'Alt'}
            </button>
          </h1>
        </div>
        {/* ad */}
        <div className="flex items-center">
          <div className="col-span-1 flex items-center pt-4 ">
            <label htmlFor="showAd" className="cursor-pointer">
              <FaRegEye className="text-2xl text-slate-300" />
            </label>
            <input type="checkbox" id="showAd" className="modal-toggle" />
            <div className="modal" role="dialog">
              <div className="modal-box">
                <ShowAd />
              </div>
            </div>
          </div>
          {/* add ad modal */}
          <div className="col-span-1 flex items-center pt-4 ml-2">
            <label
              htmlFor="addAd"
              className="cursor-pointer flex items-center gap-2 text-slate-300 font-semibold"
            >
              <FaPlusSquare className="text-2xl text-slate-300" /> Add Ad
            </label>
            <input type="checkbox" id="addAd" className="modal-toggle" />
            <div className="modal" role="dialog">
              <div className="modal-box">
                <AddAd />
              </div>
            </div>
          </div>
        </div>
      </div>
      {alt ? (
        <div className="bg-slate-50 p-4 text-black rounded-xl mt-5 mx-1  md:mx-10 lg:mx-20 ">
          <form className="" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-y-6 gap-8 text-start">
              {/* Brand Name */}
              <div className="text-start grid grid-cols-12 gap-3  ">
                <div className="col-span-10">
                  <label className="label">
                    <span className="label-text   font-semibold">
                      Brand Name
                    </span>
                  </label>

                  <select
                    onChange={e => setCarBrand(e.target.value)}
                    className="select select-secondary pr-10 md:pr-0  md:w-full p-2 text-sm"
                  >
                    <option className=" pb-3 " disabled selected>
                      Select Your Car Brand
                    </option>

                    {brands.map(brand => (
                      <option>{brand?.name}</option>
                    ))}
                  </select>
                </div>

                {/* Show brand modal */}
                <div className="col-span-1 flex items-center pt-4 ">
                  <label htmlFor="my_modal_6" className="cursor-pointer">
                    <FaRegEye className="text-2xl text-indigo-700" />
                  </label>
                  <input
                    type="checkbox"
                    id="my_modal_6"
                    className="modal-toggle"
                  />
                  <div className="modal" role="dialog">
                    <div className="modal-box">
                      <ShowBrandName />
                    </div>
                  </div>
                </div>

                {/* add brand modal */}
                <div className="col-span-1 flex items-center pt-4 ml-2">
                  <label htmlFor="my_modal_7" className="cursor-pointer">
                    <FaPlusSquare className="text-2xl text-primary" />
                  </label>
                  <input
                    type="checkbox"
                    id="my_modal_7"
                    className="modal-toggle"
                  />
                  <div className="modal" role="dialog">
                    <div className="modal-box">
                      <AddBrandName />
                    </div>
                  </div>
                </div>
              </div>

              {/* Body Types */}
              <div className="text-start grid grid-cols-12 gap-3  ">
                <div className="col-span-10">
                  <div className="text-start">
                    <label className="label">
                      <span className="label-text   font-semibold">
                        Body Types
                      </span>
                    </label>
                    <select
                      onChange={e => setBodyType(e.target.value)}
                      className="select select-secondary pr-10 md:pr-0 md:w-full p-2 text-sm"
                    >
                      <option className=" pb-3 " disabled selected>
                        Select Car Body Types
                      </option>
                      {/* <option className="text-lg text-blue-900 font-bold">
                    OUR SEEDS
                  </option> */}
                      bodyTypes
                      {bodyTypes.map(brand => (
                        <option>{brand?.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* show body types */}
                <div className="col-span-1 flex items-center pt-4 ">
                  <label htmlFor="showBodyType" className="cursor-pointer">
                    <FaRegEye className="text-2xl text-indigo-700" />
                  </label>
                  <input
                    type="checkbox"
                    id="showBodyType"
                    className="modal-toggle"
                  />
                  <div className="modal" role="dialog">
                    <div className="modal-box">
                      <ShowBodyType />
                    </div>
                  </div>
                </div>

                {/* add body types modal */}
                <div className="col-span-1 flex items-center pt-4 ml-2">
                  <label htmlFor="addBodyType" className="cursor-pointer">
                    <FaPlusSquare className="text-2xl text-primary" />
                  </label>
                  <input
                    type="checkbox"
                    id="addBodyType"
                    className="modal-toggle"
                  />
                  <div className="modal" role="dialog">
                    <div className="modal-box">
                      <AddBodyType />
                    </div>
                  </div>
                </div>
              </div>
              {/* name */}
              <div className="text-start">
                <label className="label">
                  <span className="label-text   font-semibold">Car Name</span>
                </label>
                <input
                  type="text"
                  placeholder="Car Name"
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('name', {
                    required: {
                      value: true,
                      message: 'Car Name is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.name?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.name?.message}
                    </span>
                  )}
                </label>
              </div>

              {/* Price */}
              <div className="text-start">
                <label className="label">
                  <span className="label-text font-semibold">Car Price</span>
                </label>
                <input
                  type="number"
                  placeholder="Car Price"
                  className="input input-bordered bg-white sm:w-full hover:shadow-xl shadow-inner"
                  {...register('price', {
                    required: {
                      value: true,
                      message: 'Car Price is Required',
                    },
                    min: {
                      value: 0,
                      message: 'Car Price must be a positive number',
                    },
                  })}
                />
                <label className="label">
                  {errors.price?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors.price?.message}
                    </span>
                  )}
                  {errors.price?.type === 'min' && (
                    <span className="label-text-alt text-red-500">
                      {errors.price?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* Registration Year */}
              <div className="text-start">
                <label className="label">
                  <span className="label-text   font-semibold">
                    Registration Year
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Registration Year"
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('year', {
                    required: {
                      value: true,
                      message: 'Registration Year is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.year?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.year?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* Fuel */}
              <div className="text-start">
                <label className="label">
                  <span className="label-text   font-semibold">Fuel</span>
                </label>
                <input
                  type="text"
                  placeholder="Fuel Name"
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('fuel', {
                    required: {
                      value: true,
                      message: 'Fuel Name is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.fuel?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.fuel?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* Engin */}
              <div className="text-start">
                <label className="label">
                  <span className="label-text   font-semibold">Engin</span>
                </label>
                <input
                  type="text"
                  placeholder="Engin (cc)"
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('engin', {
                    required: {
                      value: true,
                      message: 'Engin (cc) is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.engin?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.engin?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* Drive Type */}
              <div className="text-start">
                <label className="label">
                  <span className="label-text   font-semibold">Drive</span>
                </label>
                <input
                  type="text"
                  placeholder="Drive "
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('drive', {
                    required: {
                      value: true,
                      message: 'Drive  is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.drive?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.drive?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* Reference No */}
              <div className="text-start">
                <label className="label">
                  <span className="label-text   font-semibold">
                    Reference No
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Reference No"
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('referenceNo', {
                    required: {
                      value: true,
                      message: 'Reference No is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.referenceNo?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.referenceNo?.message}
                    </span>
                  )}
                </label>
              </div>
              {/*Model Code*/}
              <div className="text-start">
                <label className="label">
                  <span className="label-text   font-semibold">Model Code</span>
                </label>
                <input
                  type="text"
                  placeholder="Model Code"
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('modelCode', {
                    required: {
                      value: true,
                      message: 'Model Code is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.modelCode?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.modelCode?.message}
                    </span>
                  )}
                </label>
              </div>

              {/* Model Grade */}
              <div>
                <label className="label">
                  <span className="label-text   font-semibold">
                    Model Grade
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Model Grade"
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('modelGrade', {
                    required: {
                      value: true,
                      message: 'Model Grade is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.modelGrade?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.modelGrade?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* Manufacture Year */}
              <div>
                <label className="label">
                  <span className="label-text   font-semibold">
                    Manufacture Year
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Manufacture Year"
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('manufactureYear', {
                    required: {
                      value: true,
                      message: 'Manufacture Year is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.manufactureYear?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.manufactureYear?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* Transmission */}
              <div>
                <label className="label">
                  <span className="label-text   font-semibold">
                    Transmission
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Transmission"
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('transmission', {
                    required: {
                      value: true,
                      message: 'Transmission is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.transmission?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.transmission?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* Mileage. */}
              <div>
                <label className="label">
                  <span className="label-text   font-semibold">Mileage</span>
                </label>
                <input
                  type="number"
                  placeholder="Mileage"
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('mileage', {
                    required: {
                      value: true,
                      message: 'Mileage is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.mileage?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.mileage?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* Engine Capacity */}
              <div>
                <label className="label">
                  <span className="label-text   font-semibold">
                    Engine Capacity
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="  Engine Capacity"
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('engineCapacity', {
                    required: {
                      value: true,
                      message: ' Engine Capacity is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.engineCapacity?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.engineCapacity?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* Fuel Type */}
              <div>
                <label className="label">
                  <span className="label-text   font-semibold">Fuel Type</span>
                </label>
                <input
                  type="text"
                  placeholder="Fuel Type"
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('fuelType', {
                    required: {
                      value: true,
                      message: 'Fuel Type is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.fuelType?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.fuelType?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* No. of Seats */}
              <div>
                <label className="label">
                  <span className="label-text   font-semibold">
                    No. of Seats
                  </span>
                </label>
                <input
                  type="number"
                  placeholder="No. of Seats"
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('noOfSeats', {
                    required: {
                      value: true,
                      message: 'No. of Seats is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.noOfSeats?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.noOfSeats?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* No. of Doors. */}
              <div>
                <label className="label">
                  <span className="label-text   font-semibold">
                    No. of Doors.
                  </span>
                </label>
                <input
                  type="number"
                  placeholder="No. of Doors."
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('noOfDoors.', {
                    required: {
                      value: true,
                      message: 'No. of Doors. is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.noOfDoors?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.noOfDoors?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* Steering */}
              <div>
                <label className="label">
                  <span className="label-text   font-semibold">Steering</span>
                </label>
                <input
                  type="text"
                  placeholder="Steering"
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('steering', {
                    required: {
                      value: true,
                      message: 'Steering is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.steering?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.steering?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* Dimension */}
              <div>
                <label className="label">
                  <span className="label-text   font-semibold">Dimension</span>
                </label>
                <input
                  type="text"
                  placeholder="Dimension"
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('dimension', {
                    required: {
                      value: true,
                      message: 'Dimension is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.dimension?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.dimension?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* VIN */}
              <div>
                <label className="label">
                  <span className="label-text   font-semibold">VIN</span>
                </label>
                <input
                  type="text"
                  placeholder="VIN"
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('vIN', {
                    required: {
                      value: true,
                      message: 'VIN is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.vIN?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.vIN?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* Exterior Color */}
              <div>
                <label className="label">
                  <span className="label-text   font-semibold">
                    Exterior Color
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Exterior Color"
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('exteriorColor', {
                    required: {
                      value: true,
                      message: 'Exterior Color is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.exteriorColor?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.exteriorColor?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* Auction Grade */}
              <div>
                <label className="label">
                  <span className="label-text   font-semibold">
                    Auction Grade
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Auction Grade"
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('auctionGrade', {
                    required: {
                      value: true,
                      message: 'Auction Grade is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.auctionGrade?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.auctionGrade?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* Cylinders */}
              <div>
                <label className="label">
                  <span className="label-text   font-semibold">Cylinders</span>
                </label>
                <input
                  type="text"
                  placeholder="Cylinders"
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('Cylinders', {
                    required: {
                      value: true,
                      message: 'Cylinders is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.Cylinders?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.Cylinders?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* Kilometers */}
              <div>
                <label className="label">
                  <span className="label-text   font-semibold">Kilometers</span>
                </label>
                <input
                  type="text"
                  placeholder="Kilometers"
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('Kilometers', {
                    required: {
                      value: true,
                      message: 'Kilometers is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.Kilometers?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.Kilometers?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* Engine Size */}
              <div>
                <label className="label">
                  <span className="label-text   font-semibold">
                    Engine Size
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Engine Size"
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('EngineSize', {
                    required: {
                      value: true,
                      message: 'Engine Size is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.EngineSize?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.EngineSize?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* Engine Type */}
              <div>
                <label className="label">
                  <span className="label-text   font-semibold">
                    Engine Type
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Engine Type"
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('EngineType', {
                    required: {
                      value: true,
                      message: 'Engine Type is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.EngineType?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.EngineType?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* RegPlate */}
              <div>
                <label className="label">
                  <span className="label-text   font-semibold">Reg Plate</span>
                </label>
                <input
                  type="text"
                  placeholder="Reg Plate"
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('RegPlate', {
                    required: {
                      value: true,
                      message: 'Reg Plate is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.RegPlate?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.RegPlate?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* RegExpiry */}
              <div>
                <label className="label">
                  <span className="label-text   font-semibold">Reg Expiry</span>
                </label>
                <input
                  type="text"
                  placeholder="Reg Expiry"
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('RegExpiry', {
                    required: {
                      value: true,
                      message: 'Reg Expiry is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.RegExpiry?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.RegExpiry?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* StockNumber */}
              <div>
                <label className="label">
                  <span className="label-text   font-semibold">
                    Stock Number
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Stock Number"
                  className="input input-bordered bg-white  sm:w-full   hover:shadow-xl shadow-inner"
                  {...register('StockNumber', {
                    required: {
                      value: true,
                      message: 'Stock Number is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.StockNumber?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.StockNumber?.message}
                    </span>
                  )}
                </label>
              </div>

              {/* Images  1*/}
              <div>
                <label className="label">
                  <span className="label-text   font-semibold">Image 1</span>
                </label>
                <input
                  type="file"
                  placeholder="Image1"
                  className="input input-bordered bg-white w-[280px] md:w-full pt-2   hover:shadow-xl shadow-inner"
                  {...register('image1', {
                    required: {
                      value: true,
                      message: 'Image1 is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.image1?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.image1?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* Images  2*/}
              <div>
                <label className="label">
                  <span className="label-text   font-semibold">Image 2</span>
                </label>
                <input
                  type="file"
                  placeholder="Image2"
                  className="input input-bordered bg-white  w-[280px] md:w-full pt-2   hover:shadow-xl shadow-inner"
                  {...register('image2', {
                    required: {
                      value: true,
                      message: 'Image2 is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.image2?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.image2?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* Images  3*/}
              <div>
                <label className="label">
                  <span className="label-text   font-semibold">Image 3</span>
                </label>
                <input
                  type="file"
                  placeholder="Image3"
                  className="input input-bordered bg-white  w-[280px] md:w-full pt-2   hover:shadow-xl shadow-inner"
                  {...register('image3', {
                    required: {
                      value: true,
                      message: 'Image3 is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.image3?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.image3?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* Images  4*/}
              <div>
                <label className="label">
                  <span className="label-text   font-semibold">Image 4</span>
                </label>
                <input
                  type="file"
                  placeholder="Image1"
                  className="input input-bordered bg-white  w-[280px] md:w-full pt-2   hover:shadow-xl shadow-inner"
                  {...register('image4', {
                    required: {
                      value: true,
                      message: 'Image4 is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.image4?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.image4?.message}
                    </span>
                  )}
                </label>
              </div>
              {/* Images  5*/}
              {/* <div>
                <label className="label">
                  <span className="label-text   font-semibold">
                    Image 5
                  </span>
                </label>
                <input
                  type="file"
                  placeholder="Image5"
                  className="input input-bordered bg-white  sm:w-full pt-2   hover:shadow-xl shadow-inner"
                  {...register('image5', {
                    required: {
                      value: true,
                      message: 'Image5 is Required',
                    },
                  })}
                />
                <label className="label">
                  {errors.image5?.type === 'required' && (
                    <span className="label-text-alt text-red-500">
                      {errors?.image5?.message}
                    </span>
                  )}
                </label>
              </div> */}
            </div>

            {loading ? (
              <button className="btn btn-primary mt-5 w-full disable text-white">
                Loading ...
              </button>
            ) : (
              <>
                {brandName && bodyType ? (
                  <input
                    className="btn btn-primary mt-5 w-full disable text-white"
                    type="submit"
                    value="ADD"
                  />
                ) : (
                  <input
                    disabled
                    className="btn btn-primary mt-5 w-full disable text-white"
                    type="submit"
                    value="ADD"
                  />
                )}
              </>
            )}
          </form>
        </div>
      ) : (
        <AltAddCar />
      )}
    </div>
  );
};

export default AddCar;
