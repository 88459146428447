import React from 'react';
import { FaCheck } from 'react-icons/fa';

const Accessories = () => {
  return (
    <div className="md:w-[600px] bg-lime-50 mt-10">
      <h1 className="border-x-[1px] border-slate-400 border-t-[3px] border border-t-blue-500 p-2 text-center font-semibold">
        Accessories
      </h1>
      <div className="border-[1px] border-t-0 border-slate-400">
        <h1 className="p-3 pb-0 font-bold">Comfort</h1>
        <div className="grid grid-cols-3   ">
          <h1 className=" p-3 text-xs md:text-md flex gap-2 items-center">
            <FaCheck className="text-blue-500" />
            Cruise Control
          </h1>

          <h1 className=" p-3 text-xs md:text-md flex gap-2 items-center">
            <FaCheck className="text-blue-500" />
            Power Mirror
          </h1>
          <h1 className=" p-3 text-xs md:text-md flex gap-2 items-center">
            <FaCheck className="text-blue-500" />
            Power Steering
          </h1>
        </div>
      </div>
      <div className="border-[1px] border-t-0 border-slate-400">
        <h1 className="p-3 pb-0 font-bold">Others</h1>
        <div className="grid grid-cols-3   ">
          <h1 className=" p-3 text-xs md:text-md flex gap-2 items-center">
            <FaCheck className="text-blue-500" />
            A/C
          </h1>

          <h1 className=" p-3 text-xs md:text-md flex gap-2 items-center">
            <FaCheck className="text-blue-500" />
            Alloy Wheels
          </h1>
          <h1 className=" p-3 text-xs md:text-md flex gap-2 items-center">
            <FaCheck className="text-blue-500" />
            AM/FM
          </h1>
        </div>
      </div>
      <div className="border-[1px] border-t-0 border-slate-400">
        <h1 className="p-3 pb-0 font-bold">Safety</h1>
        <div className="grid grid-cols-3   ">
          <h1 className=" p-3 text-xs md:text-md flex gap-2 items-center">
            <FaCheck className="text-blue-500" />
            ABS
          </h1>

          <h1 className=" p-3 text-xs md:text-md flex gap-2 items-center">
            <FaCheck className="text-blue-500" />
            Airbag
          </h1>
          <h1 className=" p-3 text-xs md:text-md flex gap-2 items-center">
            <FaCheck className="text-blue-500" />
            Power Door Locks
          </h1>
        </div>
      </div>
      <div className="border-[1px] border-t-0 border-slate-400">
        <h1 className="p-3 pb-0 font-bold">Windows</h1>
        <div className="grid grid-cols-3   ">
          <h1 className=" p-3 text-xs md:text-md flex gap-2 items-center">
            <FaCheck className="text-blue-500" />
            Power Window
          </h1>

          <h1 className=" p-3 text-xs md:text-md flex gap-2 items-center">
            <FaCheck className="text-blue-500" />
            Rear Window Defroster
          </h1>
          <h1 className=" p-3 text-xs md:text-md flex gap-2 items-center">
            <FaCheck className="text-blue-500" />
            Rear Window Wiper
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Accessories;
