import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../../../../Share/Footer';
import LoadingCar1 from '../../../../Share/LoadingCar1';
import MiniCart from '../../Cart/MiniCart/MiniCart';
import LatestCars from '../../LatestCars/LatestCars';

const BrandFilter = ({ brandName }) => {
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    setLoading(true);
    fetch(`https://car-server-aus.onrender.com/carBrand/${brandName}`)
      .then(res => res.json())
      .then(data => {
        setLoading(false);
        setBrands(data);
      });
  }, [brandName]);

  if (loading) {
    return <LoadingCar1 />;
  }

  return (
    <div className="pt-16 bg-white ">
      <div>
        <h1 className="text-3xl text-center py-5 font-semibold  border-b-[1px]   bg-rose-200">
          Our <span className="text-orange-600"></span> Cars
        </h1>

        {brands.length > 0 ? (
          <div className=" mt-5 mx-5 md:mx-10 lg:mx-16 ">
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 lg:mx-20 gap-10 ">
              {brands.map(car => (
                <MiniCart key={car._id} car={car} />
              ))}
            </div>
          </div>
        ) : (
          <div className="flex justify-center">
            <img
              src="https://img.pikbest.com/element_our/20220405/bg/1c936d8b550cd.png!bw700"
              alt=""
            />
          </div>
        )}
      </div>
      <LatestCars />
      <Footer />
    </div>
  );
};

export default BrandFilter;
