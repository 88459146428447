import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const EditCars = () => {
  const { id } = useParams();
  const [car, setCars] = useState({});
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const navigator = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch(`https://car-server-aus.onrender.com/car/${id}`)
      .then(res => res.json())
      .then(data => setCars(data));
  }, [id]);
  // console.log(car);
  const onSubmit = data => {
    setLoading(true);
    const updateCar = {
      name: data.name || car.name,
      quantity: data.quantity || car.quantity,
      price: data.price || car.price,
      offerPrice: data.offerPrice || car.offerPrice,
      year: data.year || car.year,
      manufactureYear: data.manufactureYear || car.manufactureYear,
      mileage: data.mileage || car.mileage,
      dimension: data.dimension || car.dimension,
      engineCapacity: data.engineCapacity || car.engineCapacity,
      exteriorColor: data.exteriorColor || car.exteriorColor,
      fuel: data.fuel || car.fuel,
      fuelType: data.fuelType || car.fuelType,
      modelCode: data.modelCode || car.modelCode,
      modelGrade: data.modelGrade || car.modelGrade,
      noOfDoors: data.noOfDoors || car.noOfDoors,
      noOfSeats: data.noOfSeats || car.noOfSeats,
      steering: data.steering || car.steering,
      transmission: data.transmission || car.transmission,
      auctionGrade: data.auctionGrade || car.auctionGrade,
      referenceNo: data.referenceNo || car.referenceNo,
      Cylinders: data.Cylinders || car.Cylinders,
      Kilometers: data.Kilometers || car.Kilometers,
      EngineSize: data.EngineSize || car.EngineSize,
      EngineType: data.EngineType || car.EngineType,
      RegPlate: data.RegPlate || car.RegPlate,
      RegExpiry: data.RegExpiry || car.RegExpiry,
      StockNumber: data.StockNumber || car.StockNumber,
      brandName: data.brandName || car.brandName,
      bodyType: data.bodyType || car.bodyType,
      img1: data.img1 || car.img1,
      img2: data.img2 || car.img2,
      img3: data.img3 || car.img3,
      img4: data.img4 || car.img4,
    };
    fetch(`https://car-server-aus.onrender.com/updateCar/${id}`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(updateCar),
    })
      .then(res => res.json())
      .then(data => {
        setLoading(false);
        toast.success('Update Successful');

        navigator('/dashboard/manageCar');
      });
    // console.log(updateCar);
  };
  return (
    <div className="mt-5 md:mx-20">
      <h1 className="text-center mb-5 text-3xl font-semibold text-indigo-900">
        Update Car <span className="text-orange-800">{car?.name}</span>
      </h1>
      <form
        className="bg-gradient-to-r from-pink-100 via-purple-100 to-indigo-100 p-4 rounded-lg shadow-xl"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5">
          {/* name */}
          <div>
            <h1 className="pl-2">Brand Name</h1>
            <input
              type="text"
              placeholder={car?.brandName ? car?.brandName : 'Enter Brand Name'}
              className="input input-bordered h-10 bg-white w-full "
              {...register('brandName')}
            />
          </div>
          {/* name */}
          <div>
            <h1 className="pl-2">Body Type</h1>
            <input
              type="text"
              placeholder={car?.bodyType ? car?.bodyType : 'Enter Body Type'}
              className="input input-bordered h-10 bg-white w-full "
              {...register('bodyType')}
            />
          </div>
          {/* name */}
          <div>
            <h1 className="pl-2">Name</h1>
            <input
              type="text"
              placeholder={car?.name ? car?.name : 'Enter Car Name'}
              className="input input-bordered h-10 bg-white w-full "
              {...register('name')}
            />
          </div>
          {/* Quantity */}
          <div>
            <h1 className="pl-2">Quantity</h1>
            <input
              type="text"
              placeholder={car?.quantity ? car?.quantity : 'Enter Car Quantity'}
              className="input input-bordered h-10 bg-white w-full "
              {...register('quantity')}
            />
          </div>
          {/* price */}
          <div>
            <h1 className="pl-2">Price</h1>
            <input
              type="text"
              placeholder={car?.price ? car?.price : 'Enter Car Price'}
              className="input input-bordered h-10 bg-white w-full "
              {...register('price')}
            />
          </div>
          {/* offer price */}
          <div>
            <h1 className="pl-2">Offer Price</h1>
            <input
              type="text"
              placeholder={
                car?.offerPrice ? car?.offerPrice : 'Enter Car Offer Price'
              }
              className="input input-bordered h-10 bg-white w-full "
              {...register('offerPrice')}
            />
          </div>
          {/* Year */}
          <div>
            <h1 className="pl-2">Year</h1>
            <input
              type="text"
              placeholder={car?.year ? car?.year : 'Enter Car Year'}
              className="input input-bordered h-10 bg-white w-full "
              {...register('year')}
            />
          </div>
          {/* Manufacture Year */}
          <div>
            <h1 className="pl-2">Manufacture Year</h1>
            <input
              type="text"
              placeholder={
                car?.manufactureYear
                  ? car?.manufactureYear
                  : 'Enter Car Manufacture Year'
              }
              className="input input-bordered h-10 bg-white w-full "
              {...register('manufactureYear')}
            />
          </div>
          {/* Mileage */}
          <div>
            <h1 className="pl-2">Mileage</h1>
            <input
              type="text"
              placeholder={car?.mileage ? car?.mileage : 'Enter Car Mileage'}
              className="input input-bordered h-10 bg-white w-full "
              {...register('mileage')}
            />
          </div>
          {/* Dimension */}
          <div>
            <h1 className="pl-2">Dimension</h1>
            <input
              type="text"
              placeholder={
                car?.dimension ? car?.dimension : 'Enter Car Dimension'
              }
              className="input input-bordered h-10 bg-white w-full "
              {...register('dimension')}
            />
          </div>
          {/* engineCapacity */}
          <div>
            <h1 className="pl-2">Engine Capacity</h1>
            <input
              type="text"
              placeholder={
                car?.engineCapacity
                  ? car?.engineCapacity
                  : 'Enter Car Engine Capacity'
              }
              className="input input-bordered h-10 bg-white w-full "
              {...register('engineCapacity')}
            />
          </div>
          {/* exteriorColor */}
          <div>
            <h1 className="pl-2">Exterior Color</h1>
            <input
              type="text"
              placeholder={
                car?.exteriorColor
                  ? car?.exteriorColor
                  : 'Enter Car exteriorColor'
              }
              className="input input-bordered h-10 bg-white w-full "
              {...register('exteriorColor')}
            />
          </div>
          {/* fuel */}
          <div>
            <h1 className="pl-2">Fuel</h1>
            <input
              type="text"
              placeholder={car?.fuel ? car?.fuel : 'Enter Car fuel'}
              className="input input-bordered h-10 bg-white w-full "
              {...register('fuel')}
            />
          </div>
          {/* fuelType */}
          <div>
            <h1 className="pl-2">fuelType</h1>
            <input
              type="text"
              placeholder={car?.fuelType ? car?.fuelType : 'Enter Car fuelType'}
              className="input input-bordered h-10 bg-white w-full "
              {...register('fuelType')}
            />
          </div>
          {/* modelCode */}
          <div>
            <h1 className="pl-2">ModelCode</h1>
            <input
              type="text"
              placeholder={
                car?.modelCode ? car?.modelCode : 'Enter Car modelCode'
              }
              className="input input-bordered h-10 bg-white w-full "
              {...register('modelCode')}
            />
          </div>
          {/* modelGrade */}
          <div>
            <h1 className="pl-2">Model Grade</h1>
            <input
              type="text"
              placeholder={
                car?.modelGrade ? car?.modelGrade : 'Enter Car modelGrade'
              }
              className="input input-bordered h-10 bg-white w-full "
              {...register('modelGrade')}
            />
          </div>
          {/* noOfSeats */}
          <div>
            <h1 className="pl-2">No Of Seats</h1>
            <input
              type="text"
              placeholder={
                car?.noOfSeats ? car?.noOfSeats : 'Enter Car noOfSeats'
              }
              className="input input-bordered h-10 bg-white w-full "
              {...register('noOfSeats')}
            />
          </div>
          {/* noOfDoors */}
          <div>
            <h1 className="pl-2">No Of Doors</h1>
            <input
              type="text"
              placeholder={
                car?.noOfDoors ? car?.noOfDoors : 'Enter Car noOfDoors'
              }
              className="input input-bordered h-10 bg-white w-full "
              {...register('noOfDoors')}
            />
          </div>
          {/* steering */}
          <div>
            <h1 className="pl-2">Steering</h1>
            <input
              type="text"
              placeholder={car?.steering ? car?.steering : 'Enter Car steering'}
              className="input input-bordered h-10 bg-white w-full "
              {...register('steering')}
            />
          </div>
          {/* transmission */}
          <div>
            <h1 className="pl-2">Transmission</h1>
            <input
              type="text"
              placeholder={
                car?.transmission ? car?.transmission : 'Enter Car transmission'
              }
              className="input input-bordered h-10 bg-white w-full "
              {...register('transmission')}
            />
          </div>
          {/* referenceNo */}
          <div>
            <h1 className="pl-2">Reference No</h1>
            <input
              type="text"
              placeholder={
                car?.referenceNo ? car?.referenceNo : 'Enter Car referenceNo'
              }
              className="input input-bordered h-10 bg-white w-full "
              {...register('referenceNo')}
            />
          </div>
          {/* auctionGrade */}
          <div>
            <h1 className="pl-2">Auction Grade</h1>
            <input
              type="text"
              placeholder={
                car?.auctionGrade ? car?.auctionGrade : 'Enter Car auctionGrade'
              }
              className="input input-bordered h-10 bg-white w-full "
              {...register('auctionGrade')}
            />
          </div>
          {/* Cylinders */}
          <div>
            <h1 className="pl-2">Cylinders</h1>
            <input
              type="text"
              placeholder={
                car?.Cylinders ? car?.Cylinders : 'Enter Car Cylinders'
              }
              className="input input-bordered h-10 bg-white w-full "
              {...register('Cylinders')}
            />
          </div>
          {/* Kilometers */}
          <div>
            <h1 className="pl-2">Kilometers</h1>
            <input
              type="text"
              placeholder={
                car?.Kilometers ? car?.Kilometers : 'Enter Car Kilometers'
              }
              className="input input-bordered h-10 bg-white w-full "
              {...register('Kilometers')}
            />
          </div>
          {/* EngineSize */}
          <div>
            <h1 className="pl-2">Engine Size</h1>
            <input
              type="text"
              placeholder={
                car?.EngineSize ? car?.EngineSize : 'Enter Car Engine Size'
              }
              className="input input-bordered h-10 bg-white w-full "
              {...register('EngineSize')}
            />
          </div>
          {/* EngineType */}
          <div>
            <h1 className="pl-2">Engine Type</h1>
            <input
              type="text"
              placeholder={
                car?.EngineType ? car?.EngineType : 'Enter Car Engine Type'
              }
              className="input input-bordered h-10 bg-white w-full "
              {...register('EngineType')}
            />
          </div>
          {/* RegPlate */}
          <div>
            <h1 className="pl-2">Reg Plate</h1>
            <input
              type="text"
              placeholder={
                car?.RegPlate ? car?.RegPlate : 'Enter Car Reg Plate'
              }
              className="input input-bordered h-10 bg-white w-full "
              {...register('RegPlate')}
            />
          </div>
          {/* RegExpiry */}
          <div>
            <h1 className="pl-2">Reg Expiry</h1>
            <input
              type="text"
              placeholder={
                car?.RegExpiry ? car?.RegExpiry : 'Enter Car Reg Expiry'
              }
              className="input input-bordered h-10 bg-white w-full "
              {...register('RegExpiry')}
            />
          </div>
          {/* StockNumber */}
          <div>
            <h1 className="pl-2">Stock Number</h1>
            <input
              type="text"
              placeholder={
                car?.StockNumber ? car?.StockNumber : 'Enter Car Stock Number'
              }
              className="input input-bordered h-10 bg-white w-full "
              {...register('StockNumber')}
            />
          </div>

          {/* img1 */}
          <div>
            <h1 className="pl-2">Img1</h1>
            <input
              type="text"
              placeholder={car?.img1 ? car?.img1 : 'Enter Car img1'}
              className="input input-bordered h-10 bg-white w-full "
              {...register('img1')}
            />
          </div>
          {/* img2 */}
          <div>
            <h1 className="pl-2">Img2</h1>
            <input
              type="text"
              placeholder={car?.img2 ? car?.img2 : 'Enter Car Img2'}
              className="input input-bordered h-10 bg-white w-full "
              {...register('img2')}
            />
          </div>
          {/* img3 */}
          <div>
            <h1 className="pl-2">Img3</h1>
            <input
              type="text"
              placeholder={car?.img3 ? car?.img3 : 'Enter Car Img3'}
              className="input input-bordered h-10 bg-white w-full "
              {...register('img3')}
            />
          </div>
          {/* img4 */}
          <div>
            <h1 className="pl-2">Img4</h1>
            <input
              type="text"
              placeholder={car?.img4 ? car?.img4 : 'Enter Car img4'}
              className="input input-bordered h-10 bg-white w-full "
              {...register('img4')}
            />
          </div>
        </div>
        {loading ? (
          <button className="btn btn-primary text-white w-full mt-5 cursor-not-allowed">
            Loading ...
          </button>
        ) : (
          <input
            className="btn btn-primary text-white w-full mt-5"
            type="submit"
            value="SUbmit"
          />
        )}
      </form>
    </div>
  );
};

export default EditCars;
