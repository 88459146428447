import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import bookingImg from '../../../Images/Logo/book.jpg';
import auth from '../../../firebase.init';
import MyBookingCar from './MyBookingCar';

const MyBookingCars = () => {
  const [cars, setCar] = useState([]);
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const email = user?.email;
  const navigator = useNavigate();
  useEffect(() => {
    fetch(`https://car-server-aus.onrender.com/buyCarByEmail/${email}`)
      .then(res => res.json())
      .then(data => setCar(data));
  }, [cars]);

  const handlePayment = id => {
    navigate(`/payment/${id}`);
  };

  const handleRemove = id => {
    const proceed = window.confirm('Are You Sure ?');

    if (proceed) {
      const url = `https://car-server-aus.onrender.com/buyCarDelete/${id}`;
      fetch(url, {
        method: 'DELETE',
      })
        .then(res => res.json())
        .then(data => {
          const remaining = cars.filter(product => product._id !== id);
          setCar(remaining);
          // toast.success('Successfully Delete');
          Swal.fire({
            icon: 'success',
            title: 'Successfully Delete',
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
  };

  const handleClick = () => {
    navigator('/');
  };
  return (
    <div
      style={{
        backgroundImage: `url("")`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
      }}
      className="mt-5 pb-32 pt-20 bg-white"
    >
      {/* <img
        src="https://s3.us-east-2.amazonaws.com/hda/assets/loading-car.gif"
        alt=""
      /> */}
      <div className="">
        {cars.length === 0 ? (
          <div className="flex items-center justify-center h-screen cursor-pointer">
            <img onClick={handleClick} src={bookingImg} alt="" />
          </div>
        ) : (
          <>
            {' '}
            {cars.map((car, index) => (
              <MyBookingCar
                key={car._id}
                car={car}
                index={index + 1}
                handleRemove={handleRemove}
                handlePayment={handlePayment}
              ></MyBookingCar>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default MyBookingCars;
