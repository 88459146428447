import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CarBooking from './CarBooking';

const CarBookings = () => {
  const [buys, setBuys] = useState([1]);
  const [chat, setChat] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    fetch(`https://car-server-aus.onrender.com/buyCars`)
      .then(res => res.json())
      .then(data => setBuys(data));
  }, [buys]);

  const handlePayment = id => {
    navigate(`/payment/${id}`);
  };
    const handleDelivered = id => {
      const updateDelivered = { delivered: true };
      fetch(`https://car-server-aus.onrender.com/buyDelivered/${id}`, {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(updateDelivered),
      })
        .then(res => res.json())
        .then(data => {
          toast.success('Successfully Delivered  Car ');
        });
    };

    const handleRemove = id => {
      const proceed = window.confirm('Are You Sure ?');
      if (proceed) {
        const url = `https://car-server-aus.onrender.com/buyCarDelete/${id}`;
        fetch(url, {
          method: 'DELETE',
        })
          .then(res => res.json())
          .then(data => {
            const remaining = buys.filter(product => product._id !== id);
            setBuys(remaining);
            toast.success('Successfully Delete');
          });
      }
    };
    return (
      <div className="w-[320px] md:w-[1000px] lg:w-[1270px]">
        {/* Cart */}

        <div className="overflow-auto lg:h-screen p-2">
          <table className="table w-full ">
            <thead>
              <tr className="text-center ">
                <th className="text-xs">Index</th>
                <th className="text-xs"></th>
                <th className="text-xs">Name</th>
                <th className="text-xs">Customer Name</th>
                <th className="text-xs">Phone</th>
                <th className="text-xs">Address</th>
                <th className="text-xs">Total Price</th>
                <th className="text-xs">Date</th>
                <th className="text-xs">Time</th>
                <th className="text-xs">Status</th>
                <th className="text-xs">Remove</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {buys.map((buy, index) => (
                <CarBooking
                  key={buy._id}
                  car={buy}
                  index={index + 1}
                  handleRemove={handleRemove}
                  handlePayment={handlePayment}
                  handleDelivered={handleDelivered}
                ></CarBooking>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
};

export default CarBookings;
