import React from 'react';

const CarSpecification = ({ car }) => {
  return (
    <div className="md:w-[600px] bg-slate-50 font-robot">
      <div>
        <h1 className="border-x-[1px] font-serif border-slate-400 border-t-[3px] border border-t-blue-500 p-2 text-center font-semibold">
          Car Specification
        </h1>
      </div>

      <div className="grid grid-cols-4  border-[1px] border-slate-400 ">
        <h1 className="bg-slate-200 p-3 text-xs md:text-md">Reference No.</h1>
        <h1 className="p-3 text-xs md:text-md"> {car?.referenceNo}</h1>
        <h1 className="bg-slate-200 p-3 text-xs md:text-md">Model Code</h1>
        <h1 className="p-3 text-xs md:text-md"> {car?.modelCode}</h1>
      </div>
      <div className="grid grid-cols-4  border-[1px] border-t-0 border-slate-400 ">
        <h1 className="bg-slate-200 p-3 text-xs md:text-md">
          Registration Year
        </h1>
        <h1 className="p-3 text-xs md:text-md"> {car?.year}</h1>
        <h1 className="bg-slate-200 p-3 text-xs md:text-md">Model Grade</h1>
        <h1 className="p-3 text-xs md:text-md"> {car?.modelGrade}</h1>
      </div>
      <div className="grid grid-cols-4  border-[1px] border-t-0 border-slate-400 ">
        <h1 className="bg-slate-200 p-3 text-xs md:text-md">Kilometers</h1>
        <h1 className="p-3 text-xs md:text-md"> {car?.Kilometers}</h1>
        <h1 className="bg-slate-200 p-3 text-xs md:text-md">Body</h1>
        <h1 className="p-3 text-xs md:text-md"> {car?.bodyType}</h1>
      </div>
      <div className="grid grid-cols-4  border-[1px] border-t-0 border-slate-400 ">
        <h1 className="bg-slate-200 p-3 text-xs md:text-md">
          Manufacture Year
        </h1>
        <h1 className="p-3 text-xs md:text-md"> {car?.manufactureYear}</h1>
        <h1 className="bg-slate-200 p-3 text-xs md:text-md">Transmission</h1>
        <h1 className="p-3 text-xs md:text-md">{car?.transmission}</h1>
      </div>
      <div className="grid grid-cols-4  border-[1px] border-t-0 border-slate-400 ">
        <h1 className="bg-slate-200 p-3 text-xs md:text-md">Mileage.</h1>
        <h1 className="p-3 pr-0 text-xs md:text-md">
          {car?.mileage} km <br />{' '}
          <span className="text-[11px]">
            (Approx. {parseInt(car?.mileage * 0.621371)} miles)
          </span>
        </h1>
        <h1 className="bg-slate-200 p-3 text-xs md:text-md">Engine Capacity</h1>
        <h1 className="p-3 text-xs md:text-md">
          {car?.engineCapacity} cc <br /> (1.80 liters)
        </h1>
      </div>
      <div className="grid grid-cols-4  border-[1px] border-t-0 border-slate-400 ">
        <h1 className="bg-slate-200 p-3 text-xs md:text-md">Fuel Type.</h1>
        <h1 className="p-3 text-xs md:text-md">{car?.fuelType}</h1>
        <h1 className="bg-slate-200 p-3 text-xs md:text-md">No. of Seats</h1>
        <h1 className="p-3 text-xs md:text-md">{car?.noOfSeats}</h1>
      </div>
      <div className="grid grid-cols-4  border-[1px] border-t-0 border-slate-400 ">
        <h1 className="bg-slate-200 p-3 text-xs md:text-md">Cylinders</h1>
        <h1 className="p-3 text-xs md:text-md">{car?.Cylinders}</h1>
        <h1 className="bg-slate-200 p-3 text-xs md:text-md">Engine Size</h1>
        <h1 className="p-3 text-xs md:text-md">{car?.EngineSize}</h1>
      </div>
      <div className="grid grid-cols-4  border-[1px] border-t-0 border-slate-400 ">
        <h1 className="bg-slate-200 p-3 text-xs md:text-md">Engine Type</h1>
        <h1 className="p-3 text-xs md:text-md">{car?.EngineType}</h1>
        <h1 className="bg-slate-200 p-3 text-xs md:text-md">Reg Plate</h1>
        <h1 className="p-3 text-xs md:text-md">{car?.RegPlate}</h1>
      </div>
      <div className="grid grid-cols-4  border-[1px] border-t-0 border-slate-400 ">
        <h1 className="bg-slate-200 p-3 text-xs md:text-md">Reg Expiry</h1>
        <h1 className="p-3 text-xs md:text-md">{car?.RegExpiry}</h1>
        <h1 className="bg-slate-200 p-3 text-xs md:text-md">Stock Number</h1>
        <h1 className="p-3 text-xs md:text-md">{car?.StockNumber}</h1>
      </div>
      <div className="grid grid-cols-4  border-[1px] border-t-0 border-slate-400 ">
        <h1 className="bg-slate-200 p-3 text-xs md:text-md">No. of Doors.</h1>
        <h1 className="p-3 text-xs md:text-md">{car?.noOfDoors}</h1>
        <h1 className="bg-slate-200 p-3 text-xs md:text-md">Steering</h1>
        <h1 className="p-3 text-xs md:text-md">{car?.steering}</h1>
      </div>
      <div className="grid grid-cols-4  border-[1px] border-t-0 border-slate-400 ">
        <h1 className="bg-slate-200 p-3 text-xs md:text-md">Drive Type.</h1>
        <h1 className="p-3 text-xs md:text-md">2WD</h1>
        <h1 className="bg-slate-200 p-3 text-xs md:text-md">Dimension</h1>
        <h1 className="p-3 text-xs md:text-md">{car?.dimension}</h1>
      </div>
      <div className="grid grid-cols-4  border-[1px] border-t-0 border-slate-400 ">
        <h1 className="bg-slate-200 p-3 text-xs md:text-md">
          VIN / Chassis No.
        </h1>
        <h1 className="p-3 text-xs md:text-md">{car?.vIN}</h1>
        <h1 className="bg-slate-200 p-3 text-xs md:text-md">Exterior Color</h1>
        <h1 className="p-3 text-xs md:text-md">{car?.exteriorColor}</h1>
      </div>
      <div className="grid grid-cols-4  border-[1px] border-t-0 border-slate-400 ">
        <h1 className=" p-3 text-xs md:text-md col-span-2">
          *Full VIN/Chassis no. will be shown on Invoice
        </h1>
        <h1 className="bg-slate-200 p-3 text-xs md:text-md">Auction Grade</h1>
        <h1 className="p-3 text-xs md:text-md">{car?.auctionGrade}</h1>
      </div>
    </div>
  );
};

export default CarSpecification;
