import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAllBrand from '../../../../hooks/useAllBrand';

const SearchCart = ({ setPriceFilter, setBrandName }) => {
  const [cars, setCars] = useState([]);
  // const [priceFilter, setPriceFilter] = useState([]);
  const [range, setRange] = useState('');
  const navigator = useNavigate();
  const [button1, setButton1] = useState(true);
  const [button2, setButton2] = useState(false);
  // car get by brand
  const [brands] = useAllBrand();

  useEffect(() => {
    fetch(`https://car-server-aus.onrender.com/cars`)
      .then(res => res.json())
      .then(data => setCars(data));
  }, []);

  const [lowerPrice, upperPrice] = range.split(' - ');

  const handleFilter = () => {
    navigator('/priceFilter');
    const lower = parseFloat(lowerPrice);
    const upper = parseFloat(upperPrice);
    // console.log(lower, upper);

    if (!isNaN(lower) && !isNaN(upper)) {
      const filtered = cars.filter(flat => {
        const price = parseFloat(flat?.price);
        return !isNaN(price) && price >= lower && price <= upper;
      });

      setPriceFilter(filtered);
      navigator('/priceFilter');
    }
  };

  const handleBrand = () => {
    navigator('/brandFilter');
  };
  // console.log(cars);
  console.log(range);
  return (
    <div className="ml-5 md:ml-18 lg:ml-32 pt-5 md:pt-56 mb-10  p-10">
      <div className="w-[240px] md:w-[300px] rounded-md bg-white p-3 border-[1px]">
        <h1 className="font-bold text-md md:text-xl">Find Your Right Car</h1>
        <div className=" flex justify-between items-center pt-2 md:pt-3">
          <button
            onClick={() => {
              setButton1(true);
              setButton2(false);
            }}
            className={
              button1
                ? 'px-3 md:px-5 py-1 border-[1px] border-slate-300  rounded-md font-semibold bg-black text-white mb-2'
                : 'px-3 md:px-5 py-1 border-[1px] border-slate-300  rounded-md font-semibold bg-white text-black mb-2'
            }
            // className="px-3 md:px-5 py-1 border-[1px] border-slate-300  rounded-md font-semibold bg-black text-white"
          >
            New Car
          </button>
        </div>
        {/* checked */}
        {/* <div mb-2 */}
        <div>
          <select
            onClick={e => setRange(e.target.value)}
            className="select select-bordered select-xs md:select-md w-full max-w-xs rounded-none text-xs md:text-lg py-0 font-robot"
          >
            <option disabled selected>
              Select Budget
            </option>
            <option>1 - 5000</option>
            <option>5001 - 7000</option>
            <option>7001 - 9000</option>
            <option>9001 - 11000</option>
            <option>11001 - 15000</option>
            <option>15001 - 20000</option>
            <option>20001 - 25000</option>
            <option>25001 - 90000000</option>
          </select>
          <button
            onClick={handleFilter}
            className="bg-info text-white font-semibold uppercase w-full btn-sm  rounded-md mt-5 "
          >
            Search
          </button>
          <select
            onClick={e => setBrandName(e.target.value)}
            className="select select-bordered select-xs md:select-md w-full max-w-xs rounded-none mt-3 text-xs md:text-lg "
          >
            <option disabled selected>
              All Vehicle Types
            </option>
            {brands.map(brand => (
              <option>{brand?.name}</option>
            ))}
          </select>

          <button
            onClick={handleBrand}
            className="bg-info text-white font-semibold uppercase w-full btn-sm  rounded-md mt-5"
          >
            Search
          </button>
        </div>
        {/* <div className=" mt-3 md:mt-7 flex justify-end ">
          <h1 className="flex items-center gap-x-1 cursor-pointer">
            Advanced <FaArrowRight className="mt-1 text-xs" />
          </h1>
        </div> */}
      </div>
    </div>
  );
};

export default SearchCart;
