import React from 'react';
import loading from '../../Images/Loading/loading3.gif';

const LoadingCar = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <img src={loading} alt="" />
    </div>
  );
};

export default LoadingCar;
