import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const ShowBodyType = () => {
  const [banners, setBanner] = useState([]);

  useEffect(() => {
    fetch(`https://car-server-aus.onrender.com/bodyType`)
      .then(res => res.json())
      .then(data => setBanner(data));
  }, [banners]);
  const handleDelete = id => {
    const proceed = window.confirm('Are You Sure ?');
    if (proceed) {
      const url = `https://car-server-aus.onrender.com/bodyType/${id}`;
      fetch(url, {
        method: 'DELETE',
      })
        .then(res => res.json())
        .then(data => {
          const remaining = banners.filter(product => product._id !== id);
          setBanner(remaining);
          toast.success('Successfully Delete');
        });
    }
  };
  return (
    <div>
      <div className="modal-action fixed top-0 right-0 -mt-0 z-30">
        <label
          htmlFor="showBodyType"
          className="text-white bg-red-600 px-3 text-center  text-3xl py-[0px] cursor-pointer rounded-full"
        >
          x
        </label>
      </div>
      <div>
        <div className="overflow-x-auto">
          <table className="table w-full text-center">
            {/* head */}
            <thead>
              <tr>
                <th></th>
                <th>Logo</th>
                <th>Name</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {banners.map((banner, index) => (
                <tr key={index} className="bg-base-200">
                  <td>{index + 1}</td>
                  <td>
                    {' '}
                    <img className="h-20 w-24" src={banner?.img} alt="" />
                  </td>
                  <td>{banner?.name}</td>
                  <td>
                    <h1
                      onClick={() => handleDelete(banner._id)}
                      className="btn btn-xs btn-primary text-white"
                    >
                      Remove
                    </h1>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ShowBodyType;
