import React from 'react';
import loading1 from '../../Images/Car gif/loading.gif';

const LoadingCar1 = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <img className="h-[300px]" src={loading1} alt="" />
    </div>
  );
};

export default LoadingCar1;
