import React, { useEffect, useState } from 'react';
import LoadingCar1 from '../../../Share/LoadingCar1';
import MiniCart from '../Cart/MiniCart/MiniCart';

const LatestCars = () => {
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    fetch(`https://car-server-aus.onrender.com/cars`)
      .then(res => res.json())
      .then(data => {
        setLoading(false);
        setCars(data);
      });
  }, []);

  if (loading) {
    return <LoadingCar1 />;
  }
  return (
    <div className="mt-20 mb-10">
      <h1 className=" text-center text-3xl uppercase font-semibold text-indigo-900 border-b-2 pb-2">
        our Latest Cars
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-x-5 gap-y-10 mt-5 mx-5">
        {cars
          .slice(0, 10)
          .reverse()
          .map(car => (
            <MiniCart key={car._id} car={car} />
          ))}
      </div>
    </div>
  );
};

export default LatestCars;
