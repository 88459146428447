import React, { useEffect, useState } from 'react';
import LoadingCar1 from '../../../Share/LoadingCar1';
import MiniCart from './MiniCart/MiniCart';

const TableCarts = ({
  brandName,
  selectNumber,
  setSelectNumber,
  allCar,
  setAllCar,
  searchGet,
  setSearchGet,
}) => {
  const [cars, setCars] = useState([]);
  const sliceNumber = Number(selectNumber.match(/\d+/)[0]);

  const [getCars, setGetCars] = useState([]);
  // console.log(getCars);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(`https://car-server-aus.onrender.com/cars`)
      .then(res => res.json())
      .then(data => {
        setLoading(false);
        setAllCar('No');
        setCars(data);
        setGetCars(data);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch(`https://car-server-aus.onrender.com/carBrand/${brandName}`)
      .then(res => res.json())
      .then(data => {
        setLoading(false);
        setAllCar('No');
        setCars(data);
      });
  }, [brandName]);

  useEffect(() => {
    if (allCar === 'Yes') {
      setLoading(true);
      fetch(`https://car-server-aus.onrender.com/cars`)
        .then(res => res.json())
        .then(data => {
          setLoading(false);
          setAllCar('No');
          setCars(data);
          setGetCars(data);
        });
    }
  }, [allCar, getCars]);

  if (loading) {
    return <LoadingCar1 />;
  }
  return (
    <div className=" ">
      <h1 className="pt-2 ml-5 text-2xl font-semibold text-slate-900">
        {brandName} Cars for sale ...
      </h1>
      <div className="md:mx-5 mr-2 pt-5 md:p-4 rounded-md">
        <div className="flex bg-primary py-2 items-center gap-2 mb-2 pl-2 rounded-md md:mx-5">
          <h1 className="text-white font-semibold">Show</h1>
          <select
            onClick={e => setSelectNumber(e.target.value)}
            className="select select-sm select-info w-[90px] h-[10px] text-xs pl-1 m-0 py-0"
          >
            <option>6 Cars</option>
            <option>10 Cars</option>
            <option>15 Cars</option>
            <option>20 Cars</option>
            <option>25 Cars</option>
            <option>30 Cars</option>
            <option>35 Cars</option>
            <option>40 Cars</option>
          </select>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mx-3 md:mx-5">
          {!searchGet.length > 0 ? (
            <>
              {cars
                .slice(0, sliceNumber)
                .reverse()
                .map(car => (
                  <MiniCart key={car._id} car={car} />
                ))}
            </>
          ) : (
            <>
              {searchGet
                .slice(0, sliceNumber)
                .reverse()

                .map(car => (
                  <MiniCart key={car._id} car={car} />
                ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TableCarts;

  //  <div className="overflow-x-auto">
  //    <table className="table text-center w-full ">
  //      {/* head */}
  //      <thead>
  //        <tr>
  //          <th className="border-[1px] border-rounded-xl rounded-none border-slate-400 text-white bg-primary ">
  //            Photo
  //          </th>
  //          <th className="border-[1px] border-rounded-xl  border-slate-400 text-white bg-primary">
  //            Model
  //          </th>
  //          <th className="border-[1px] border-rounded-xl  border-slate-400 text-white bg-primary">
  //            Year
  //          </th>
  //          <th className="border-[1px] border-rounded-xl  border-slate-400 text-white bg-primary">
  //            Km
  //          </th>
  //          <th className="border-[1px] border-rounded-xl  border-slate-400 text-white bg-primary">
  //            Engine
  //          </th>
  //          <th className="border-[1px] border-rounded-xl  border-slate-400 text-white bg-primary">
  //            Grade
  //          </th>
  //          <th className="border-[1px] border-rounded-xl  border-none border-slate-400 text-white bg-primary">
  //            Car Price
  //          </th>
  //          {/* <th className="border-[1px] border-rounded-xl  border-slate-400 text-white bg-primary">Total Price</th> */}
  //        </tr>
  //      </thead>
  //      <tbody>
  //        {!searchGet.length > 0 ? (
  //          <>
  //            {cars
  //              .slice(0, sliceNumber)
  //              .reverse()
  //              .map(car => (
  //                <TableCart key={car._id} car={car} />
  //              ))}
  //          </>
  //        ) : (
  //          <>
  //            {searchGet
  //              .slice(0, sliceNumber)
  //              .reverse()

  //              .map(car => (
  //                <TableCart key={car._id} car={car} />
  //              ))}
  //          </>
  //        )}
  //      </tbody>
  //    </table>
  //  </div>;