import React, { useState } from 'react';
import { toast } from 'react-toastify';

const AddBrandName = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [altImage, setAltImage] = useState('');
  const [alt, setAlt] = useState(true);

  const imageHostKey = 'ab492fa3bd4253f25e449094e4b48480';

  const handleSubmit = () => {
    // const image = data.image[0];
    setLoading(true);
    if (alt) {
      const changeUrl = { name, img: altImage };
      // console.log(changeUrl);
      fetch(`https://car-server-aus.onrender.com/brandName`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(changeUrl),
      })
        .then(res => res.json())
        .then(data => {
          setLoading(false);
          toast.success('Done Successfully ');
        });
    } else {
      const formData = new FormData();
      formData.append('image', image);
      const url = `https://api.imgbb.com/1/upload?expiration=600&key=${imageHostKey}`;
      fetch(url, {
        method: 'POST',
        body: formData,
      })
        .then(res => res.json())
        .then(imageData => {
          const image = imageData.data.url;
          const changeUrl = { name, img: image };
          // console.log(changeUrl);
          fetch(`https://car-server-aus.onrender.com/brandName`, {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
            },
            body: JSON.stringify(changeUrl),
          })
            .then(res => res.json())
            .then(data => {
              setLoading(false);
              toast.success('Done Successfully ');
              // reset();
            });
        });
    }
  };
  return (
    <div className="bg-white  flex justify-center">
      <div className="modal-action fixed top-0 right-0 -mt-0 z-30">
        <label
          htmlFor="my_modal_7"
          className="text-white bg-red-500 px-3 text-center  text-3xl py-[0px] cursor-pointer rounded-full"
        >
          x
        </label>
      </div>
      <div>
        <h1 className="text-xl mb-3">
          Add Brand Name{' '}
          {alt ? (
            <span
              onClick={() => setAlt(false)}
              className="text-slate-300  ml-10 text-sm cursor-pointer"
            >
              Alt
            </span>
          ) : (
            <span
              onClick={() => setAlt(true)}
              className="text-slate-300  ml-10 text-sm cursor-pointer"
            >
              Alt
            </span>
          )}
        </h1>

        <div>
          <input
            type="text"
            onChange={e => setName(e.target.value)}
            placeholder="Brand Name"
            className="input input-bordered bg-white w-max-xs w-80   hover:shadow-xl shadow-inner"
          />
        </div>

        {alt ? (
          <div>
            <h1 className="my-2 text-lg  text-indigo-800">Input Brand Logo</h1>
            <input
              type="img"
              onChange={e => setAltImage(e.target.value)}
              placeholder="Brand Image url"
              className="input h-[40px] input-bordered text-black   pt-1 w-80 hover:shadow-xl shadow-inner overflow-hidden"
            />
          </div>
        ) : (
          <div>
            <h1 className="my-2 text-lg  text-indigo-800">Input Brand Logo</h1>
            <input
              type="file"
              onChange={e => setImage(e.target.files[0])}
              placeholder="Brand Name"
              className="input h-[40px] input-bordered text-black   pt-1 w-80 hover:shadow-xl shadow-inner overflow-hidden"
            />
          </div>
        )}

        {loading ? (
          <h1 className="btn btn-primary mt-5 w-full disable text-white">
            Loading ...
          </h1>
        ) : (
          <h1
            onClick={handleSubmit}
            className="btn btn-primary mt-5 w-full disable text-white"
          >
            Add
          </h1>
        )}
      </div>
    </div>
  );
};

export default AddBrandName;

//  <form className="" onSubmit={handleSubmit(onSubmit2)}>
//    {/* name */}
//    <div>
//      <input
//        type="text"
//        placeholder="Brand Name"
//        className="input input-bordered bg-white w-max-xs w-80   hover:shadow-xl shadow-inner"
//        {...register('name', {
//          required: {
//            value: true,
//            message: 'Brand Name is Required',
//          },
//        })}
//      />
//      <label className="label">
//        {errors.name?.type === 'required' && (
//          <span className="label-text-alt text-red-500">
//            {errors?.name?.message}
//          </span>
//        )}
//      </label>
//    </div>

//    {/* image */}
//    <div>
//      <label className="label">
//        <span className="label-text text-lg font-semibold text-indigo-800">
//          Input Brand Logo
//        </span>
//      </label>
//      <input
//        type="file"
//        className="input h-[40px] input-bordered text-black   pt-1 w-80 hover:shadow-xl shadow-inner"
//        {...register('image', {
//          required: {
//            value: true,
//            message: 'Image is Required',
//          },
//        })}
//      />

//      <label className="label">
//        {errors.image?.type === 'required' && (
//          <span className="label-text-alt text-red-500">
//            {errors?.image?.message}
//          </span>
//        )}
//      </label>
//    </div>
//    <input
//      className="btn btn-primary mt-5 w-full disable text-white"
//      type="submit"
//      value="ADD"
//    />
//  </form>;
