import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Footer from '../../../Share/Footer';
import LatestCars from '../LatestCars/LatestCars';
import Accessories from './CartDetails/Accessories';
import CarSpecification from './CartDetails/CarSpecification';
import DetailsCarousel from './CartDetails/DetailsCarousel';
import PriceCart from './CartDetails/PriceCart';

const TableDetails = () => {
  const { id } = useParams();
  const [car, setCar] = useState({});
  const { pathname } = useLocation();
     const [ads, setAd] = useState([]);

     useEffect(() => {
       window.scrollTo(0, 0);
     }, [pathname]);

     useEffect(() => {
       fetch(`https://car-server-aus.onrender.com/car/${id}`)
         .then(res => res.json())
         .then(data => setCar(data));
     }, [id]);

     useEffect(() => {
       fetch(`https://car-server-aus.onrender.com/ad`)
         .then(res => res.json())
         .then(data => setAd(data));
     }, [ads]);
     return (
       <div className="bg-white">
         <div className="md:mx-12 pt-20 p-6 rounded-lg shadow-lg  mt-5  pb-20">
           <div className="md:flex mb-3 md:gap-x-10 lg:gap-x-16">
             <div className="w-full md:w-[600px]">
               <DetailsCarousel car={car} />
             </div>

             <PriceCart car={car} />
           </div>
           <div className="md:ml-20 mt-10 md:flex gap-x-5">
             <CarSpecification car={car} />
             <div>
               {ads
                 .slice(0, 1)
                 .reverse()
                 .map((ad, index) => (
                   <img
                     key={index}
                     className="w-[500px] h-[350px] mt-20 rounded-lg"
                     src={ad?.img}
                     alt={ad?.name}
                   />
                 ))}
             </div>
           </div>
           <div className="md:flex justify-between ">
             <div>
               <img
                 className="mt-20"
                 src="https://cdn.pixabay.com/photo/2016/10/27/10/36/arrow-1773946_960_720.png"
                 alt=""
               />
             </div>
             <Accessories />
           </div>
         </div>
         <LatestCars />
         <Footer />
       </div>
     );
};

export default TableDetails;
