import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Button.css';

const PriceCart = ({ car }) => {
  const navigator = useNavigate();
  const handleBook = id => {
    navigator(`/buyCar/${id}`);
  };
  return (
    <div className="md:w-[600px] mt-10 font-robot">
      <h1 className="text-2xl font-semibold">
        {car?.name} {car?.manufactureYear} for sale
      </h1>

      {car.offerPrice ? (
        <>
          {car.offerPrice === '0' ? (
            <h1 className="font-semibold text-indigo-800 text-lg">
              $ {car?.price}
            </h1>
          ) : (
            <div className="flex gap-5 items-center">
              <h1 className="font-semibold text-indigo-800 text-lg">
                $ {car.offerPrice}
              </h1>
              <h1 className="line-through text-red-700">$ {car.price}</h1>
            </div>
          )}
        </>
      ) : (
        <h1 className="font-semibold text-indigo-800 text-lg">
          $ {car?.price}
        </h1>
      )}
      {/* {car.offerPrice ? (
        <h1 className="text-xl text-slate-500">
          Car Price US
          <span className="line-through text-red-500 mx-2">
            $ {car?.price}
          </span>{' '}
          $ {car.offerPrice}
        </h1>
      ) : (
        <h1 className="text-xl text-slate-500">Car Price US $ {car?.price}</h1>
      )} */}

      <p>--</p>
      <p className="mt-2">- {car?.fuelType} </p>
      <h1 className="btn btn-warning btn-xs">Best Deal</h1>

      <div className="mt-4 p-3 border-dashed border-slate-400 border-[2px]">
        <div className="flex gap-x-2 items-center">
          <p>{car?.mileage} Km</p>
          <div className="w-[2px] h-[12px] bg-slate-400"></div>
          <p>{car?.fuelType}</p>{' '}
          <div className="w-[2px] h-[12px] bg-slate-400"></div>
          <p>{car?.gineCapacity} cc</p>{' '}
          <div className="w-[2px] h-[12px] bg-slate-400"></div>
          <p>4WD</p> <div className="w-[2px] h-[12px] bg-slate-400"></div>
          <p>Automatic</p>
        </div>
        <div className="mt-5">
          <h1>Pre-export Inspection</h1>
          <h1>Marine Insurance </h1>
        </div>

        <div className="md:mx-20 mt-8">
          <h6
            onClick={() => handleBook(car._id)}
            className="button-css w-full "
          >
            Book Now
          </h6>
        </div>
      </div>
    </div>
  );
};

export default PriceCart;
