import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';

const CheckoutForm = ({ price }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardError, setCartError] = useState([]);
  const [clientSecret, setClientSecret] = useState('');
  const [processing, setProcessing] = useState(false);
  const [transactionId, setTransactionId] = useState('');

  if (price) {
    // useEffect(() => {
    const url = `https://car-server-aus.onrender.com/create-payment-intent`;

    // Assuming 'price' is a state variable in your component
    const requestBody = {
      price: price,
    };

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
      .then(res => res.json())
      .then(data => {
        // console.log(data.clientSecret);
        setClientSecret(data.clientSecret);
      });
    // }, []);
  }

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    if (card === null) {
      return;
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card,
    });

    if (error) {
      setCartError(error.message);
    } else {
      setCartError('');
    }

    setProcessing(true);

    const { paymentIntent, error: confirmError } =
      await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: card,
          billing_details: {
            name: 'Dipankar Edit',
            email: 'something pora edit',
          },
        },
      });

    if (confirmError) {
      console.log(confirmError);
    }
    console.log('paymentIntent', paymentIntent);
    setProcessing(false);

    if (paymentIntent.status === 'succeeded') {
      setTransactionId(paymentIntent.id);
      // TODO
    }
  };

  return (
    <>
      <form className="w-[500px] bg-slate-200 m-5 p-5" onSubmit={handleSubmit}>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
        <button
          className="btn  btn-primary btn-outline btn-sm mt-5"
          type="submit"
          disabled={!stripe || !clientSecret || processing}
        >
          Pay
        </button>
      </form>
      {cardError && <h1 className="text-red-500 ml-10">{cardError}</h1>}
      {transactionId && (
        <p className="text-green-500">
          {' '}
          Transaction Complete with Transaction id : {transactionId}
        </p>
      )}
    </>
  );
};

export default CheckoutForm;
