import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../../../../Share/Footer';
import MiniCart from '../../Cart/MiniCart/MiniCart';
import LatestCars from '../../LatestCars/LatestCars';

const PriceFilter = ({ priceFilter }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="pt-16 bg-white ">
      <div>
        <h1 className="text-3xl text-center py-5 font-semibold  border-b-[1px]   bg-rose-200">
          Our <span className="text-orange-600"></span> Cars
        </h1>

        {priceFilter.length > 0 ? (
          <div className=" mt-5 mx-5 md:mx-10 lg:mx-16 ">
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 lg:mx-20 gap-10 ">
              {priceFilter.map(car => (
                <MiniCart key={car._id} car={car} />
              ))}
            </div>
          </div>
        ) : (
          <div className="flex justify-center h-64 mt-10">
            <img
              src="https://img.pikbest.com/element_our/20220405/bg/1c936d8b550cd.png!bw700"
              alt=""
            />
          </div>
        )}
      </div>
      <LatestCars />
      <Footer />
    </div>
  );
};

export default PriceFilter;
