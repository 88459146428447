import React from 'react';
import map from '../../../Images/Map/Share Trip Reminder.gif';

const MyBookingCar = ({ handleRemove, handlePayment, car, index }) => {
  return (
    <div className="md:flex justify-center mx-2 md:mx-0 mb-10 ">
      <div className="shadow-2xl border-2 border-blue-900 bg-white rounded-lg">
        <div className="border-b-rose-900 border-b-2">
          {' '}
          <h1 className="text-center mt-3">Product id : {car?._id}</h1>
          <h1 className="text-center">Order Number: {index}</h1>
        </div>

        <div className="grid md:grid-cols-3 md:w-[900px]  items-center text-center justify-center  mt-5">
          <div className="p-3">
            <img className="h-40 w-60 rounded-md" src={car?.car?.img1} alt="" />
          </div>
          <div className="w-[300px] p-3 text-xl mt-5 md:mt-0">
            <h1>Product Name : {car?.car?.name}</h1>
            <h1>Order Date : {car?.date}</h1>
            <h1>Order Time : {car?.time}</h1>
            <h1>Present Location : {car?.address}</h1>
            <h1 className="mt-4 ">
              Total Price :{' '}
              <span className="font-semibold text-orange-700">
                {' '}
                $ {car?.car?.price}
              </span>
            </h1>
          </div>
          <div className=" w-[200px] mt-5 md:mt-0 mb-4 md:mb-0">
            <img className="h-40 ml-16  rounded-md" src={map} alt="" />
          </div>
        </div>

        <div className="flex justify-center pb-5 bg-blue-200 rounded-b-lg pt-2 gap-x-10">
          {/* {car?.payment ? (
            <h1 className="font-extrabold text-orange-600 text-xl shadow-2xl">
              Paid
            </h1>
          ) : (
            <h6 className="">Payment</h6>
          )} */}
          <button
            onClick={() => handleRemove(car._id)}
            className="bg-lime-700 px-4 rounded-xl text-white flex items-center gap-x-2 font-semibold uppercase"
          >
            Remove{' '}
            <img
              className="w-10 h-10 rounded-3xl"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRYGCqKP7EQEjQuGN78d_tXitVtGc1JrKnVf2HaiKJjMjGlbptUkM_1-2W4X_S6_S-UqgM&usqp=CAU"
              alt=""
            />
          </button>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default MyBookingCar;
