import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../../../../Share/Footer';
import LoadingCar1 from '../../../../Share/LoadingCar1';
import LatestCars from '../../LatestCars/LatestCars';
import MiniCart from './MiniCart';

const MiniCarts = ({ bodyTypes }) => {
  const [cars, setCars] = useState([]);
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    // setLoading(true);
    fetch(`https://car-server-aus.onrender.com/carBodyType/${bodyTypes}`)
      .then(res => res.json())
      .then(data => {
        // setLoading(false);
        setCars(data);
      });
  }, [cars, bodyTypes]);
  if (loading) {
    return <LoadingCar1 />;
  }
  return (
    <div className="mt-20 bg-white ">
      <h1 className="text-3xl text-center my-5 font-semibold  border-b-[1px] border-slate-200 py-2">
        Our <span className="text-orange-600">{bodyTypes}</span> Cars
      </h1>

      {cars.length > 0 ? (
        <div className="mx-7 md:mx-10 lg:mx-20 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-10">
          {cars.map(car => (
            <MiniCart key={car._id} car={car} />
          ))}
        </div>
      ) : (
        <div className="flex justify-center">
          <img
            src="https://img.pikbest.com/element_our/20220405/bg/1c936d8b550cd.png!bw700"
            alt=""
          />
        </div>
      )}
      <LatestCars />
      <Footer />
    </div>
  );
};

export default MiniCarts;
