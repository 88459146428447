import React, { useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import './BannerStyle.css';

// import required modules
import { Autoplay, EffectCoverflow, Pagination } from 'swiper/modules';

const Banner = () => {
  const [clients, setClients] = useState([]);
  const [showDescription, setShowDescription] = useState(false);
  useEffect(() => {
    fetch(`https://car-server-aus.onrender.com/happyClient`)
      .then(res => res.json())
      .then(data => setClients(data));
  }, []);
  return (
    <div>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination, Autoplay]}
        className="mySwiper"
      >
        {clients
          .slice()
          .reverse()
          .map((client, index) => (
            <SwiperSlide
              key={index}
              onMouseEnter={() => setShowDescription(true)}
              onMouseLeave={() => setShowDescription(false)}
            >
              <img
                className="cursor-pointer"
                src={client?.img}
                alt={client?.name}
              />

              {showDescription && (
                <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 p-2">
                  <h1 className="text-indigo-100 my-2 font-semibold">
                    {client?.name}
                  </h1>
                  <p className="text-white text-sm">{client?.description}</p>
                </div>
              )}
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};

export default Banner;
