import React from 'react';

const DashboardIndex = () => {
  return (
    <div className="w-[320px] md:w-full">
      <div className="mt-5 mx-3 md:m-10 grid   grid-cols-1 md:grid-cols-2 gap-x-3 md:gap-x-16 gap-y-3 md:gap-y-10">
        <div className="  border-t-[3px] p-3 rounded-md shadow-lg border-primary bg-gradient-to-b from-white to-indigo-50">
          <h1 className="text-3xl font-bold font-mono">New Order</h1>
          <p className="text-xl text-blue-800 font-semibold">3250</p>
          <p className="text-green-600 mt-2">1 + 2% (30 days)</p>
        </div>
        <div className="  border-t-[3px] p-3 rounded-md shadow-lg border-primary bg-gradient-to-b from-white to-indigo-50">
          <h1 className="text-3xl font-semibold text-orange-700 font-mono">
            Total Income
          </h1>
          <p className="text-xl text-blue-800 font-semibold">$ 32500 </p>
          <p className="text-green-600 mt-2">1 + 2% (30 days) Income</p>
        </div>
        <div className="  border-t-[3px] p-3 rounded-md shadow-lg border-primary bg-gradient-to-b from-white to-indigo-50">
          <h1 className="text-3xl font-bold font-mono">New User</h1>
          <p className="text-xl text-blue-800 font-semibold">3250</p>
          <p className="text-green-600 mt-2">1 + 2% (30 days)</p>
        </div>
        <div className="  border-t-[3px] p-3 rounded-md shadow-lg border-primary bg-gradient-to-b from-white to-indigo-50 ">
          <h1 className="text-3xl font-semibold text-orange-700 font-mono">
            Total Expense
          </h1>
          <p className="text-xl text-blue-800 font-semibold">$ 32500 </p>
          <p className="text-green-600 mt-2">1 + 2% (30 days) Income</p>
        </div>
      </div>

      <div className="flex justify-center mt-16 w-[300px] md:w-full">
        <div className="stats shadow ">
          <div className="stat">
            <div className="stat-figure text-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block w-8 h-8 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                ></path>
              </svg>
            </div>
            <div className="stat-title">Total Likes</div>
            <div className="stat-value text-primary">25.6K</div>
            <div className="stat-desc">21% more than last month</div>
          </div>

          <div className="stat">
            <div className="stat-figure text-secondary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block w-8 h-8 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 10V3L4 14h7v7l9-11h-7z"
                ></path>
              </svg>
            </div>
            <div className="stat-title">Page Views</div>
            <div className="stat-value text-secondary">2.6M</div>
            <div className="stat-desc">21% more than last month</div>
          </div>

          <div className="stat">
            <div className="stat-figure text-secondary">
              <div className="avatar online">
                <div className="w-16 rounded-full">
                  <img src="https://daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg" />
                </div>
              </div>
            </div>
            <div className="stat-value">86%</div>
            <div className="stat-title">Tasks done</div>
            <div className="stat-desc text-secondary">31 tasks remaining</div>
          </div>
        </div>
      </div>
      {/* <Charts /> */}
    </div>
  );
};

export default DashboardIndex;
