import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import car1 from '../../../Images/Car gif/car1.webp';
import auth from '../../../firebase.init';

const BuyCar = () => {
  const { id } = useParams();
  const [user] = useAuthState(auth);
  const email = user?.email;
  const [car, setCar] = useState({});
  const navigator = useNavigate();
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const monthIndex = currentDate.getMonth(); // Month is zero-based
  const day = currentDate.getDate();

  // Get time components
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const seconds = currentDate.getSeconds();
  const monthAbbreviation = months[monthIndex];

  const date = `${year}-${monthAbbreviation}-${day}`;
  const time = `${hours}:${minutes}:${seconds}`;

  useEffect(() => {
    fetch(`https://car-server-aus.onrender.com/car/${id}`)
      .then(res => res.json())
      .then(data => setCar(data));
  }, [id]);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = data => {
    const changeData = { ...data, email, date, time, car };

    const url = `https://car-server-aus.onrender.com/buyCars`;
    fetch(url, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(changeData),
    })
      .then(res => res.json())
      .then(result => {
        Swal.fire({
          icon: 'success',
          title: 'Successfully Booked',
          showConfirmButton: false,
          timer: 1500,
        });
        reset();
        navigator('/');
      });
  };
  return (
    <div className="pt-20 mx-5 md:mx-10 lg:mx-20 flex justify-center mb-20">
      <div className="grid md:grid-cols-2  gap-x-16 border-[1px] border-slate-400 p-5 rounded-lg mt-5">
        <div>
          <div className="border-x-[1px] border-b-[1px] border-t-[4px] border-t-blue-600 border-slate-400    text-center font-semibold">
            <h1 className="py-2 text-2xl">Car Details </h1>
          </div>
          <div className="border-[1px] border-t-0  border-slate-400 grid grid-cols-2     ">
            <h1 className="py-2 pl-5">Car Name </h1>
            <h1 className=" pl-2 py-2 font-semibold">: {car?.name}</h1>
          </div>
          <div className="border-[1px] border-t-0  border-slate-400 grid grid-cols-2     ">
            <h1 className="py-2 pl-5">Car Model </h1>
            <h1 className=" pl-2 py-2 font-semibold">: {car?.modelCode}</h1>
          </div>
          <div className="border-[1px] border-t-0 border-slate-400 grid grid-cols-2     ">
            <h1 className="py-2 pl-5">Car Price </h1>
            <h1 className=" pl-2 py-2 font-semibold">: AUS $ {car?.price}</h1>
          </div>
          <div className="border-[1px] border-t-0 border-slate-400 grid grid-cols-2  ">
            <h1 className="py-2 pl-5">Car Quantity </h1>
            <h1 className=" pl-2 py-2 font-semibold">: 1</h1>
          </div>
          <div className="border-[1px] border-t-0 border-slate-400 grid grid-cols-2  ">
            <h1 className="py-2 pl-5">Car Engine </h1>
            <h1 className=" pl-2 py-2 font-semibold">: {car?.engine}</h1>
          </div>

          <div className="mt-10">
            <img src={car1} alt="" />
          </div>
        </div>
        <div className="border-[1px] border-slate-300 rounded-lg px-6 py-4 mt-10 md:mt-0">
          <form className="" onSubmit={handleSubmit(onSubmit)}>
            {/* Name */}
            <div>
              <label className="label  text-xl font-semibold">
                <span className="label-text  text-xl">Name</span>
              </label>
              <input
                type="text"
                placeholder="Name"
                className="input input-bordered bg-white w-full   hover:shadow-xl shadow-inner"
                {...register('name', {
                  required: {
                    value: true,
                    message: 'Name is Required',
                  },
                })}
              />
              <label className="label">
                {errors.name?.type === 'required' && (
                  <span className="label-text-alt text-red-500">
                    {errors?.name?.message}
                  </span>
                )}
              </label>
            </div>
            {/* Phone */}
            <div>
              <label className="label  text-xl font-semibold">
                <span className="label-text  text-xl">Phone</span>
              </label>
              <input
                type="phone"
                placeholder="Phone Number"
                className="input input-bordered bg-white w-full   hover:shadow-xl shadow-inner"
                {...register('phone', {
                  required: {
                    value: true,
                    message: 'Phone is Required',
                  },
                })}
              />
              <label className="label">
                {errors.phone?.type === 'required' && (
                  <span className="label-text-alt text-red-500">
                    {errors?.phone?.message}
                  </span>
                )}
              </label>
            </div>
            {/* address  */}
            <div>
              <label className="label">
                <span className="label-text  text-xl font-semibold">
                  Address
                </span>
              </label>
              <textarea
                type="text"
                placeholder="Address"
                className="input input-bordered bg-white w-full   h-20 pt-1 hover:shadow-xl shadow-inner"
                {...register('address', {
                  required: {
                    value: true,
                    message: 'Address is Required',
                  },
                })}
              />
              <label className="label">
                {errors.address?.type === 'required' && (
                  <span className="label-text-alt text-red-500">
                    {errors?.address?.message}
                  </span>
                )}
              </label>
            </div>
            <input
              className="btn   btn-primary mt-5 w-full text-white"
              type="submit"
              value="Book Now"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default BuyCar;
