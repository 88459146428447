import React from 'react';
import { Link } from 'react-router-dom';

// import img1 from '../../../../Images/Body Types/convertible.webp';
import useAllBodyTypes from '../../../hooks/useAllBodyTypes';

const BodyType = ({ setBodyType }) => {
  const [bodyTypes] = useAllBodyTypes();
  return (
    <div className="mx-4 md:mx-10 lg:mx-20 mt-10 lg:mt-16">
      <h1 className="text-3xl mb-5 text-center font-bold">Body Types</h1>
      <div className="flex justify-center">
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-10 ">
          {bodyTypes.map(bodyType => (
            <>
              <Link to="/miniCart" onClick={() => setBodyType(bodyType?.name)}>
                <div className="border-[1px]  border-primary w-[150px] md:w-[248px] h-[130px] md:h-[180px] rounded-md  cursor-grabbing ">
                  <img
                    className="w-full h-[92px] md:h-[142px]   rounded-md "
                    src={bodyType?.img}
                    alt={bodyType?.name}
                  />
                  <h1 className="text-center py-2 md:mt-0   rounded-b-md text-white font-semibold bg-primary">
                    {bodyType?.name}
                  </h1>
                </div>
              </Link>
            </>
          ))}

          {/* <Link to="/miniCart" onClick={() => setBodyType('Convertible')}>
            <div className="border-[1px]  border-yellow-400 hover:border-red-600 w-[150px] md:w-[250px] h-[130px] md:h-[150px] rounded-md cursor-grabbing pt-2">
              <img
                className="w-[100px] h-[60px] pt-3 md:w-[200px] md:h-[100px] rounded-md ml-[25px]"
                src={img1}
                alt="Convertible"
              />
              <h1 className="text-center py-2 md:mt-1 mt-5 rounded-b-md text-white font-semibold bg-primary">
                Convertible
              </h1>
            </div>
          </Link> */}
          {/* <Link to="/miniCart" onClick={() => setBodyType('SUV')}>
            <div className="border-[1px]  border-yellow-400 hover:border-red-600 w-[150px] md:w-[250px] h-[130px] md:h-[150px] rounded-md cursor-grabbing pt-2">
              <img
                className="w-[100px] h-[60px] pt-3 md:w-[200px] md:h-[100px] rounded-md ml-[25px]"
                src={img2}
                alt="suv"
              />
              <h1 className="text-center py-2 md:mt-1 mt-5 rounded-b-md text-white font-semibold bg-primary ">
                SUV
              </h1>
            </div>
          </Link>
          <Link to="/miniCart" onClick={() => setBodyType('Sedan')}>
            <div className="border-[1px]  border-yellow-400 hover:border-red-600 w-[150px] md:w-[250px] h-[130px] md:h-[150px] rounded-md cursor-grabbing pt-2">
              <img
                className="w-[100px] h-[60px] pt-3 md:w-[200px] md:h-[100px] rounded-md ml-[25px]"
                src={img3}
                alt="sedan"
              />
              <h1 className="text-center py-2 md:mt-1 mt-5 rounded-b-md text-white font-semibold bg-primary">
                Sedan{' '}
              </h1>
            </div>
          </Link>
          <Link to="/miniCart" onClick={() => setBodyType('Hatchback')}>
            <div className="border-[1px]  border-yellow-400 hover:border-red-600 w-[150px] md:w-[250px] h-[130px] md:h-[150px] rounded-md cursor-grabbing pt-2">
              <img
                className="w-[100px] h-[60px] pt-3 md:w-[200px] md:h-[100px] rounded-md ml-[25px]"
                src={img4}
                alt="hatchback"
              />
              <h1 className="text-center py-2 md:mt-1 mt-5 rounded-b-md text-white font-semibold bg-primary">
                Hatchback
              </h1>
            </div>
          </Link>
          <Link to="/miniCart" onClick={() => setBodyType('Mini')}>
            <div className="border-[1px]  border-yellow-400 hover:border-red-600 w-[150px] md:w-[250px] h-[130px] md:h-[150px] rounded-md cursor-grabbing pt-2">
              <img
                className="w-[100px] h-[60px] pt-3 md:w-[200px] md:h-[100px] rounded-md ml-[25px]"
                src={img5}
                alt="mini"
              />
              <h1 className="text-center py-2 md:mt-1 mt-5 rounded-b-md text-white font-semibold bg-primary">
                Mini{' '}
              </h1>
            </div>
          </Link>
          <Link to="/miniCart" onClick={() => setBodyType('Coupe')}>
            <div className="border-[1px]  border-yellow-400 hover:border-red-600 w-[150px] md:w-[250px] h-[130px] md:h-[150px] rounded-md cursor-grabbing pt-2">
              <img
                className="w-[100px] h-[60px] pt-3 md:w-[200px] md:h-[100px] rounded-md ml-[25px]"
                src={img6}
                alt="coupe"
              />
              <h1 className="text-center py-2 md:mt-1 mt-5 rounded-b-md text-white font-semibold bg-primary">
                Coupe
              </h1>
            </div>
          </Link>
          <Link to="/miniCart" onClick={() => setBodyType('Minivan')}>
            <div className="border-[1px]  border-yellow-400 hover:border-red-600 w-[150px] md:w-[250px] h-[130px] md:h-[150px] rounded-md cursor-grabbing pt-2">
              <img
                className="w-[100px] h-[60px] pt-3 md:w-[200px] md:h-[100px] rounded-md ml-[25px]"
                src={img7}
                alt="minivan"
              />
              <h1 className="text-center py-2 md:mt-1 mt-5 rounded-b-md text-white font-semibold bg-primary">
                Minivan
              </h1>
            </div>
          </Link>
          <Link to="/miniCart" onClick={() => setBodyType('Bus')}>
            <div className="border-[1px]  border-yellow-400 hover:border-red-600 w-[150px] md:w-[250px] h-[130px] md:h-[150px] rounded-md cursor-grabbing pt-2">
              <img
                className="w-[100px] h-[60px] pt-3 md:w-[200px] md:h-[100px] rounded-md ml-[25px]"
                src={img8}
                alt="bus"
              />
              <h1 className="text-center py-2 md:mt-1 mt-5 rounded-b-md text-white font-semibold bg-primary">
                Bus
              </h1>
            </div>
          </Link>
          <Link to="/miniCart" onClick={() => setBodyType('Truck')}>
            <div className="border-[1px]  border-yellow-400 hover:border-red-600 w-[150px] md:w-[250px] h-[130px] md:h-[150px] rounded-md cursor-grabbing pt-2">
              <img
                className="w-[100px] h-[60px] pt-3 md:w-[200px] md:h-[100px] rounded-md ml-[25px]"
                src={img9}
                alt="truck"
              />
              <h1 className="text-center py-2 md:mt-1 mt-5 rounded-b-md text-white font-semibold bg-primary">
                Truck
              </h1>
            </div>
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default BodyType;
