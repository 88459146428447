import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../../../firebase.init';

const User = ({ user, index, handleAdmin }) => {
  const [users] = useAuthState(auth);
  console.log(users.email);
  const profile =
    'https://static.vecteezy.com/system/resources/thumbnails/019/900/322/small/happy-young-cute-illustration-face-profile-png.png';
  return (
    <tr>
      <td>{index}</td>
      <td>
        <img className="w-10 h-10 md:w-20 md:h-16" src={profile} alt="" />
      </td>
      <td>{user?.name}</td>
      <td>{user?.email}</td>
      <td>
        <div className="flex justify-center">
          {user.admin ? (
            <button
              disabled
              onClick={() => handleAdmin(user._id, true)}
              className="btn btn-sm btn-primary "
            >
              Add Admin
            </button>
          ) : (
            <h6
              onClick={() => handleAdmin(user._id, true)}
              className="text-[10px] bg-orange-500 border-yellow-200"
            >
              Add Admin
            </h6>
          )}
        </div>
      </td>
      <td>
        {user.admin ? (
          <>
            {user.email === users.email ? (
              <button
                disabled
                onClick={() => handleAdmin(user._id, false)}
                className="btn btn-sm btn-primary text-white"
              >
                Remove Admin
              </button>
            ) : (
              <button
                onClick={() => handleAdmin(user._id, false)}
                className="btn btn-sm btn-primary text-white"
              >
                Remove Admin
              </button>
            )}
          </>
        ) : (
          <button
            disabled
            onClick={() => handleAdmin(user._id, false)}
            className="btn btn-sm btn-primary text-white"
          >
            Remove Admin
          </button>
        )}
      </td>
    </tr>
  );
};

export default User;
