import React from 'react';
import { FaHandPointRight } from 'react-icons/fa';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import useAllBrand from '../../../hooks/useAllBrand';

const PopularBrands = ({ setBrandName }) => {
  const [brands] = useAllBrand();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  };
  return (
    <div className=" pb-10 mt-20 z-0">
      <h1 className="pl-5 md:pl-10 py-2 mb-5 text-xl md:text-3xl bg-primary font-extrabold flex items-center text-white">
        Our Popular Brands
        <FaHandPointRight
          data-aos="fade-right"
          data-aos-easing="linear"
          data-aos-duration="1500"
          className="ml-10 text-white"
        />
      </h1>
      <div className=" pl-5 rounded-lg px-3 ">
        <Carousel
          className="rounded-lg pl-3 bg-primary"
          responsive={responsive}
        >
          {brands.map(brand => (
            <Link onClick={e => setBrandName(brand?.name)} to="/brandFilter">
              <div className="card w-[100px] md:w-48 bg-base-100 text-black  mt-2 mb-3  shadow-2xl   hover:shadow-lg">
                <figure>
                  <img
                    className="  pic-style mt-3 w-[40px] h-[30px] md:w-[60px] md:h-[60px]"
                    src={brand?.img}
                    alt={brand?.name}
                  />
                </figure>
                <div className="pb-2 px-2  pt-5">
                  <h2 className="text-center md:text-xl font-semibold">
                    {brand?.name}
                  </h2>
                </div>
              </div>
            </Link>
          ))}
          {/* <Link to="/">
            <div className="card w-[100px] md:w-48 bg-base-100 text-black  mt-2 mb-3  shadow-2xl   hover:shadow-lg">
              <figure>
                <img
                  className="  pic-style mt-3 w-[40px] h-[30px] md:w-[60px] md:h-[60px]"
                  src="https://cdn.freelogovectors.net/wp-content/uploads/2018/04/toyotalogo1.png"
                  alt="ac"
                />
              </figure>
              <div className="pb-2 px-2  pt-5">
                <h2 className="text-center md:text-xl font-semibold">Toyota</h2>
              </div>
            </div>
          </Link>
          <Link to="/">
            <div className="card w-[100px] md:w-48  bg-base-100 text-black  mt-2 mb-3  shadow-2xl  hover:shadow-lg">
              <figure>
                <img
                  className="  pic-style mt-3 w-[40px] h-[30px] md:w-[60px] md:h-[60px]"
                  src="https://png.monster/wp-content/uploads/2021/03/pngwing.com-4-208ee93f.png"
                  alt="ac"
                />
              </figure>
              <div className="pb-2 px-2  pt-5">
                <h2 className="text-center md:text-xl font-semibold">BMW</h2>
              </div>
            </div>
          </Link>
          <Link to="/">
            <div className="card w-[100px] md:w-48 bg-base-100 text-black  mt-2 mb-3  shadow-2xl  hover:shadow-lg">
              <figure>
                <img
                  className=" pic-style mt-3 w-[40px] h-[30px] md:w-[60px] md:h-[60px]"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/Suzuki_logo_2.svg/2560px-Suzuki_logo_2.svg.png"
                  alt="ac"
                />
              </figure>
              <div className="pb-2 px-2  pt-5">
                <h2 className="text-center md:text-xl font-semibold">Suzuki</h2>
              </div>
            </div>
          </Link>
          <Link to="/">
            <div className="card w-[100px] md:w-48 bg-base-100 text-black  mt-2 mb-3  shadow-2xl  hover:shadow-lg">
              <figure>
                <img
                  className="pic-style mt-3 w-[40px] h-[30px] md:w-[60px] md:h-[60px]"
                  src="https://www.freepnglogos.com/uploads/audi-logo-2.png"
                  alt="ac"
                />
              </figure>
              <div className="pb-2 px-2  pt-5">
                <h2 className="text-center md:text-xl font-semibold">Audi</h2>
              </div>
            </div>
          </Link>
          <Link to="/">
            <div className="card w-[100px] md:w-48  bg-base-100 text-black  mt-2 mb-3  shadow-2xl  hover:shadow-lg">
              <figure>
                <img
                  className="pic-style mt-3 w-[40px] h-[30px] md:w-[60px] md:h-[60px]"
                  src="https://i.pinimg.com/originals/b1/e9/57/b1e9574195198de342a63907f30e947d.png"
                  alt="ac"
                />
              </figure>
              <div className="pb-2 px-2  pt-5">
                <h2 className="text-center md:text-xl font-semibold">
                  Mercedes
                </h2>
              </div>
            </div>
          </Link>
          <Link to="/">
            <div className="card w-[100px] md:w-48  bg-base-100 text-black  mt-2 mb-3  shadow-2xl  hover:shadow-lg">
              <figure>
                <img
                  className="pic-style mt-3 w-[40px] h-[30px] md:w-[60px] md:h-[60px]"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8e/Tata_logo.svg/1200px-Tata_logo.svg.png"
                  alt="ac"
                />
              </figure>
              <div className="pb-2 px-2  pt-5">
                <h2 className="text-center md:text-xl font-semibold">Tata</h2>
              </div>
            </div>
          </Link>
          <Link to="/">
            <div className="card w-[100px] md:w-48  bg-base-100 text-black  mt-2 mb-3  shadow-2xl  hover:shadow-lg">
              <figure>
                <img
                  className="pic-style mt-3 w-[40px] h-[30px] md:w-[60px] md:h-[60px]"
                  src="https://png.monster/wp-content/uploads/2021/03/pngwing.com-4-208ee93f.png"
                  alt="ac"
                />
              </figure>
              <div className="pb-2 px-2  pt-5">
                <h2 className="text-center md:text-xl font-semibold">BMW</h2>
              </div>
            </div>
          </Link>
          <Link to="/">
            <div className="card w-[100px] md:w-48  bg-base-100 text-black  mt-2 mb-3  shadow-2xl  hover:shadow-lg">
              <figure>
                <img
                  className="pic-style mt-3 w-[40px] h-[30px] md:w-[60px] md:h-[60px]"
                  src="https://png.monster/wp-content/uploads/2021/03/pngwing.com-4-208ee93f.png"
                  alt="ac"
                />
              </figure>
              <div className="pb-2 px-2  pt-5">
                <h2 className="text-center md:text-xl font-semibold">BMW</h2>
              </div>
            </div>
          </Link>
          <Link to="/">
            <div className="card w-[100px] md:w-48  bg-base-100 text-black  mt-2 mb-3  shadow-2xl  hover:shadow-lg">
              <figure>
                <img
                  className="pic-style mt-3 w-[40px] h-[30px] md:w-[60px] md:h-[60px]"
                  src="https://png.monster/wp-content/uploads/2021/03/pngwing.com-4-208ee93f.png"
                  alt="ac"
                />
              </figure>
              <div className="pb-2 px-2  pt-5">
                <h2 className="text-center md:text-xl font-semibold">BMW</h2>
              </div>
            </div>
          </Link> */}
        </Carousel>
      </div>
    </div>
  );
};

export default PopularBrands;
