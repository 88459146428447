import { useEffect, useState } from 'react';

const useAllBrand = () => {
  const [brands, setBrand] = useState([]);

  useEffect(() => {
    fetch(`https://car-server-aus.onrender.com/brandName`)
      .then(res => res.json())
      .then(data => setBrand(data));
  }, [brands]);
  return [brands, setBrand];
};

export default useAllBrand;
