import { useEffect, useState } from 'react';

const useAllBodyTypes = () => {
  const [bodyTypes, setBodyTypes] = useState([]);

  useEffect(() => {
    fetch(`https://car-server-aus.onrender.com/bodyType`)
      .then(res => res.json())
      .then(data => setBodyTypes(data));
  }, [bodyTypes]);
  return [bodyTypes, setBodyTypes];
};

export default useAllBodyTypes;
