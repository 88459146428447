import React from 'react';

const ShowBanner = ({ client, index, handleDelete }) => {
  return (
    <div className="grid grid-cols-12 text-start text-xs md:text-lg overflow-auto ">
      <div className="col-span-5 border-[1px] border-t-0 border-r-0 border-indigo-900 px-1 md:px-4  py-2 ">
        <img className="h-12  w-16 md:h-20 md:w-24" src={client?.img} alt="" />
      </div>
      <div className="col-span-4 md:col-span-3 border-[1px] border-r-0 border-t-0 border-indigo-900 px-1 md:px-4 py-2 ">
        <h1> {client?.name}</h1>
      </div>

      <div className="col-span-3 md:col-span-1 border-[1px] border-t-0 border-indigo-900   py-2  pt-3 md:pt-10 ">
        <button
          onClick={() => handleDelete(client?._id)}
          className="w-14 md:w-20 h-10"
        >
          <img
            src="https://app.tryzulu.com/assets/static/images/delete-gif-dark-mode.gif"
            alt=""
          />
        </button>
      </div>
    </div>
  );
};

export default ShowBanner;
